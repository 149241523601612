import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const derrEsser: IProductData = {
  id: "empirical-derresser",
  name: "Empirical Labs ELI DerrEsser",
  description: "Desser / Dynamic Section from the Lil FrEQ as a module for API 500 Series, with Vertical faceplate.",
  images: ["/img/empirical_derresser_vertical.png", "/img/empirical_derresser_front.png", "/img/empirical_derresser_pair.png"],
  details: [{
    label: "Description",
    markdown: `
[See the EL500 rack version (horizontal).](/products/empirical-derresser-h)  

One of the coolest and most useful tools ever invented for the 500 Series mounting format is the Empirical Labs “DerrEsser” (a.k.a. the “EL-DS”).  

This seemingly small package combines a LOT of punch and control.  

  
There are two main modes in the “DerrEsser”.  

The first is the “de-esser”, and the second (not necessarily in this order) is the “high frequency limiter”.  

While kind of similar in thought, the two modes are rather different in operation.  

Additionally to “de-essing” and “high frequency limiting” the “DerrEsser” can also act as a “HPF” (High Pass Filter) or “LPF” ( Low Pass Filter).  

When the “DerrEsser” is used as a “de-esser” the unit compares the quantity of high frequency material as it relates to the low frequency content of a sound and adjusts the high frequencies accordingly so no matter the level the relationship between the high frequency and low frequency information remains balanced.  

  
The “corner frequency” for the action of the “de-esser” is controlled by the frequency selection knob at the bottom of the module.  

This corner frequency selection is made quite easy with the “LISTEN” function engaged.  

When “LISTEN” is engaged it becomes amazingly quick and easy to identify any problem frequencies (finger squeaks from acoustic guitars, “ssss” or “whistle” problems within a vocal, etc.), while you employ the “de-essing” function to control these problems / issues and move on with your day.  

  
The “HF Limiter” is an entirely different, and as far as we know, an entirely unique device that solves all kinds of problems while making your day remarkably easier.  

The “HF Limiter” works like most limiters, but only on the high frequency content.  

It has a “knee”, and drops the level of the information that crosses the “threshold” so the limiter will tame the volume of the high frequency signals that has crossed the user determined threshold.  

However, what makes this unit unique is the shape of the limiting curve, with its smooth unobtrusive knee and its dependence on frequency as determined from the frequency selection knob on the front of the unit.  

Unlike “de-esser” mode, when the “HF Limiter” is engaged it is only interested in the overall volume of the high frequency information on a “stand alone” basis – the “HF Limiter” does NOT compare the high frequency information to the low frequency information as is done in “de-esser” mode.  

  
**BUT WAIT – THERE’S MORE!!!**  
If you’re a fan of “pop music” from yesteryear you have undoubtedly heard the brilliant, bright, ringing and expressive piano sounds on Elton John or Bruce Hornsby recordings.   

You may have marveled at the “airy” backing vocals on Def Lepard or later CARS recordings.  

Well now you can achieve VERY similar results in the privacy of your own home!!  


  
One of the high profile users of the “EL-DS” discovered that if you engage the “HPF” with the “LISTEN” function, the result (which can be controlled through the frequency selection knob) is that only the “tippy-top” frequencies of the signal make it through the unit.   

That user found that by using a pair of “Derr-Esser”s with a pair of “EL-8X” units that they could achieve this effect with ease.    

This has apparently become part of that particular user’s mixing regimen where there is ALWAYS a “stereo aux” send set up with a pair of “EL-DS”s and an “EL-8XS” set that return on a fixed level “aux return”.    

`
}, {
    label: "The DerrEsser Has Four Modes",
      markdown: `
**DS MODE** – (Bypass Button IN, all other buttons OUT).  
As a de-esser in the DS mode (HF Limit OFF),  the threshold can be set to tame harsh S’s (sibilants) that result from an overly bright singer or from EQ’ing to make a vocal sound aggressive and clear.  
The DS threshold is not level dependant, and compares the amount of high frequency content to the low frequency content, so adjusting the overall level does NOT affect the threshold.  
Easy to set and forget.  

Great care was taken to ensure this DS section meets the Empirical Labs reputation for originality and ease of use.  
We use the latest technology VCA, specially selected for glitch free, distortion free, and noise free performance.  
The time constants were carefully selected and the corner frequency is user adjustable with a high performance active crossover.  


**HF LIMIT** – (Bypass Button IN, HF LIM IN, all other buttons OUT).  
In this mode, the DerrEsser essentially acts as a high frequency compressor.  
If there is enough high frequency content to exceed the threshold, then gain reduction occurs.  
It IS level sensitive, but responds much more to high frequencies.  
This mode is more of a general high frequency smoothing device, and is probably the first carefully tailored soft knee HF limiter in existence.  


**HIGHPASS** – (Bypass Button IN, Listen IN, HP/LP OUT, HF LIM doesn’t matter). 
By using the “LISTEN” Function, the user can employ the superb filter circuitry inside the DerrEsser to process their audio.  
With the LISTEN button IN, and the HP/LP switch OUT, the user will be hearing the high frequencies that are being controlled with the dynamic/compression circuits.  
The corner frequency is adjustable by the Frequency knob.  


**LOWPASS** – (Bypass Button IN, Listen IN, HP/LP IN, HF LIM doesn’t matter).  
With the LISTEN button IN, and the HP/LP button IN, the user is now listening to the low frequencies, those not affected by the VCA and dynamic/compression circuits.  
This is a 12 dB per octave filter whose corner frequency can again be adjusted by the Frequency knob. s
`  }, {
    label: "Specifications",
    markdown: `
- Frequency Response: 3 Hz to 120 kHz (-3dB points).
- Dynamic Range: 115 dB from maximum output (.5% THD soft clipping) to minimum output. Transformerless noise floor typically - 93dBu.
- Distortion: Ranges between .0035% and .01% depending on mode and settings.
- I/O: DC Coupled input and outputs. Input Balanced, Output single ended but with balanced impedance. Pin 2 hot.
- Impedance: Input Impedance is 20k Ohm. Output impedance is less than 51 ohms.
- D-S Dynamics Time Constants: Attack time is less than 1mS. Release .04 sec, fixed internally
- Power Consumption: 8 Watts max. 4 Watts Typical.`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://www.empiricallabs.com/derresser/" target="_blank">https://www.empiricallabs.com/derresser/</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.DESSER, HASHTAGS.SINGLE_SLOT, HASHTAGS.RECORDING, HASHTAGS.MIXING, HASHTAGS.MASTERING, HASHTAGS.LIVE_SOUND],
    manufacturer: BRAND.EMPIRICAL_LABS,
  },
  videos: ["https://www.youtube.com/embed/cXikFm4kDJM", "https://www.youtube.com/embed/fFbR2dmRxuI"]
}