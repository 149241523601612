import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const lilFrEQ: IProductData = {
  id: "empirical-elq-lil-freq",
  name: "Empirical Labs ELQ Lil FrEQ",
  description: "Combine with Mike-E to create an Empirical Labs channel strip!",
  images: [
    "/img/empirical_lil_freq_front.png", "/img/empirical_lil_freq_left.png", "/img/empirical_lil_freq_right.png", 
    "/img/empirical_lil_freq_pair_front.png", "/img/empirical_lil_freq_pair_left.png", "/img/empirical_lil_freq_pair_right.png", "/img/empirical_lil_freq_pair_back.png"
  ],
  details: [{
    label: "Description",
    markdown: `
The earliest equalizers were originally developed in order to “correct” the frequency response of early telephone applications.  
Using passive networks to “compensate for” the uneven frequency response of telephone systems, the Bell Labs engineers figured out that by applying filters having the opposite response curve of that system something closer to an even frequency response could be achieved(hence the term “equalization”).  
A few decades later, in early 1971, Daniel Flickenger created the first “parametric” equalizer (USPTO #3752928) which wasn’t actually called a “parametric equalizer” until George Massenburg introduced the term in a May 1972 paper presented at the 42nd convention of the Audio Engineering Society.  

Parametric Equalization gave the working engineer the ability to determine not only the amplitude (volume), and center frequency of the portion of the frequency spectrum to be “equalized”; but also granted the user the ability to define the bandwidth associated with the amplitude and center frequency of that equalizer.  
Though commonplace in today’s audio environment, the creation of this powerful toolset was to say the very least an audio engineering “game changer”.  

In 2005 the Empirical Labs “Lil FrEQ” was created to give the working engineer a much broader and far more powerful tone shaping device of unparalleled power.  
Besides the immensely powerful 4 bands of fully parametric equalization, the “Lil Freq” also includes a Hi Pass (low frequency cut) filter; a High & Low Shelving equalizer (like the first equalizers ever developed); but being Empirical Labs, the power didn’t stop there!!  

Dave Derr (Empirical Labs’ designer) then added the most musical and natural sounding “De-esser” ever created.  
The De-esser” employs tunable filters with a compressor like circuit to create what is tantamount to being a “Dynamic EQ” (which works much like having an automatic hand on a volume knob that is associated with a specific area of the high frequency spectrum), and a broader “High Frequency Limiter” that begs to be used (especially in the mix process) on vocals to increase the clarity and conveyance of the emotion of the vocal performance.  
See the DerrEsser Description for more details on this dynamic EQ section.  


While the “Shelving” equalizer works like an overall “tone control” (much like you will find on most home stereos), the “Parametric EQ” grants the engineer the ability to seek out specific elements of the audio spectrum for enhancement (or removal) with the four individually switchable bands (as in not in use, then its not in the signal path unless you would like it to be) of “Parametric EQ”.  
Additionally, with 14db of potential “boost” and “cut”; the 4 frequency bands of the “Parametric EQ” permit overlap of frequencies adjacent to each band, which can be employed to create highly musical and interesting results.  


The “Hi Pass” filter works to eliminate acoustically transmitted rumblings (passing trucks, trains, meteor strikes, and vocal “plosives”) from the final sonic presentation, while the “De-esser” / “High Frequency Limiter” permits the final shaping of a sound so it will fit brilliantly into even the most dense and complex mixes.  


Additional conveniences found on the “Lil FrEQ” include an instrument (DI) input, a “BAD!” clipping indicator lamp, as well as the option to employ the unbalanced “active” output or a “Class A” transformer balanced output for additional unique audio textures.  
`
  }, {
    label: "Specifications",
    markdown: `
- Frequency Response: 5 HZ to 140 kHz on transformerless inputs and outputs. Transformer output 10 Hz to 110 kHz.
- Dynamic Range: 120 dB from maximum output (.1% clipping) to minimum output. Greater than 110 dB signal to noise.
- Distortion: Ranges between .00056% and .005% depending on mode and settings. DS section has THD < .005%.
- I/O: DC coupled inputs and outputs and one transformer coupled output.
- Dynamic Time Constraints: Attack is approximately .5 ms. Release .04 sec fixed internally.
- Power Consumption: 15 Watts Max.
- Metal Chassis: Single height 1.75" high, 10" deep, 19" wide.
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://www.empiricallabs.com/lilfreq/" target="_blank">https://www.empiricallabs.com/lilfreq/</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.EQ, HASHTAGS.DESSER, HASHTAGS.RACK, HASHTAGS.ONE_U, HASHTAGS.RECORDING, HASHTAGS.MIXING, HASHTAGS.LIVE_SOUND],
    manufacturer: BRAND.EMPIRICAL_LABS,
  },
  videos: ["https://www.youtube.com/embed/YbZ89Hi6RJA"]
}