import { IProductData } from "../../../type/IProductData";
import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";

export const sixpack: IProductData = {
  id: "radial-six-pack",
  name: "Radial SixPack",
  description: "6 Slot desktop power rack. Great carry-with lunchbox to bring your favorite 500 modules wherever you may need to go.",
  images: ["/img/radial_engineering_sixpack_right.jpg", "/img/radial_engineering_sixpack_back.jpg"],
  details: [{
    label: "Key Features",
    markdown: `
- Portable 6 slot power rack with carry handle
- Perfect for stereo channels or as a studio side car
- Powerful 1600 milliamp supply will not sag when loaded
- Workstation ready with front panel convenience XLRs
`
  }, {
    label: "More details",
    markdown: `
- Serious power  
The design begins with a heavy 14-gauge steel construction and durable powder coat finish.  
This provides extra durability when moving the SixPack around the studio while also providing better shielding against stray magnetic fields.  
To reduce induced noise, the power supply is external and connects using a 5-pin locking XLR.  
To ensure positive contact, the module card-edge connector is mated to a double sided EDAC 15-pin receptacle that is 100% compatible with the old API pin format.  
Extra safety is built-in to ensure malfunctioning modules will not harm the SixPack or other modules that may already be connected.  
And with 1600 milliamps of shared current, the SixPack exceeds all others with an average of over 265 milliamps per slot to ensure even the most power hungry tube modules will be satisfied.

- Connections  
Rear panel connectivity includes individual XLR input and output connectors wired in parallel to ¼" TRS connectors.  
These may be used as mults or for cross-patching various modules together to create innovative sounding signal paths.  
A set of 25-pin D-Sub connectors enable the SixPack to easily integrate with a Workstation.  
Two XLR connectors are front mounted and may be routed to the D-Sub or to inputs 1 and 4 to make patching in a microphone more expedient.  
Extra features include a feed switch that patches one module into the next, a link switch to combine stereo-ready modules and Omniport, a ¼" TRS jack that adds extra functionality.
    `
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://www.radialeng.com/product/sixpack" target="_blank">https://www.radialeng.com/product/sixpack</a>
- <a to="https://www.radialeng.com/product/sixpack/specifications" target="_blank">Specifications</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.CASES, HASHTAGS.STARTING_OUT, HASHTAGS.API_500],
    manufacturer: BRAND.RADIAL,
  }
}