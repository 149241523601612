import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const vocalleveler500: IProductData = {
  id: "tegeler-vocalleveler500",
  name: "Tegeler Audio Manufaktur Vocal Leveler 500",
  description: "500er Opto Compressor",
  images: ["/img/tegeler-vl500-front.png", "/img/tegeler-vl500-side.jpg"],
  details: [{
    label: "Description",
    markdown: `
You don't want to spend hours writing automations or looking for the right plug-in setting?
You are on the go but don't want to miss the great Tegeler sound?

If this is you then our new Vocal Leveler is just right for you!
We took the Opto Compressor from our Vari Tube Recording Channel and imported it into the compact 500 format for you.

Thanks to the input transformer, opto compressor and FET, the Tegeler sound is perfectly transferred into the 500 format.

Our in-house transformers ensure a harmonic basic saturation right from the start. Then the signal goes through our tried and tested opto circuit from the VTRC. The FET finally provides a final, warm, tube-like sound refinement.
`}, {
  label: "Features",
  markdown: `
- Frequency Range: 20 - 22.000Hz
- Maximum Input Level: 20 dBu
- Input Impedance: ≥ 2.4kΩ
- Output Impedance: < 600 Ω
- Operating level +4 dBu
- Maximum Output Level: 21 dBu
- Dynamic Range: 100 dB
- Max. Gain Reduction: -12 dB
- Voltage: +- 16V
- Size: Standard API 500er Modulgröße
- Weight: 540g
- API 500 series mono Optokompressor
- Two potis: Gain reduction, Output
- Toggle switch 3 positions (left): In, bypass, link for stereo use of two Vocal Levelers
- Toggle switch 3 positions (right): Attack + Release time: fast, auto, slow
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://www.tegeler.com/VocalLeveler500/Index" target="_blank">https://www.tegeler.com/VocalLeveler500/Index</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.COMPRESSOR, HASHTAGS.SINGLE_SLOT, HASHTAGS.API_500, HASHTAGS.RECORDING, HASHTAGS.LIVE_SOUND, HASHTAGS.MIXING],
    manufacturer: BRAND.TEGELER_AUDIO,
  },
  videos: []
}