import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const hyperionEclipse: IProductData = {
  id: "wesaudio-hyperion-eclipse",
  name: "WesAudio Limited Edition Eclipse _Hyperion",
  description: "NG500 fully analog parametric equalizer with digital recall in the limited edition Eclipse colors!",
  images: ["/img/wesaudio_hyperion_eclipse_front.jpg", "/img/wesaudio_hyperion_eclipse_angle.jpg", "/img/wesaudio_hyperion_eclipse_side.jpg", "/img/wesaudio_hyperion_plugin.png"],
  details: [{
    label: "Description",
    markdown: `
Discover intuitive and elegant interface design, streamlining your interactions and control, elevating your sound sculpting journey to unprecedented heights! 
It took us one year for complex development to be in this spot, so don’t miss it out - get our newest software!


Dive into a world where sublime analog sound meets refined visual elegance and advanced digital control.

[Check out the standard version](/products/wesaudio-hyperion)
`
  }, {
    label: "Key Features",
    markdown: `
### _HYPERION
- Revitalized GUI, exclusive black plate run!
- +24dBu of headroom
- Stereo / Dual mono / Mid/Side operation mode
- 4-Band EQ with switchable gain range for 5db or 15dB boost or cut per band:
- HPF with 12dB/Oct or 6 dB/Oct slopes
- Each band features its own Bypass switch
- Essentially Noiseless parameter changes
- Proprietary THD implementation
- Flexible DAW/Live plug-in control for instant recall
- 12 touch sensitive encoders allows to record automation
- True bypass
- In and OUT metering with clip detection
- A/B compare feature
`
  }, {
    label: "Specifications",
    markdown: `
- Frequency response	10Hz-150kHz (-2dB)
- THD+N all bands gain “0”	0.003% (1kHz,0dBu)
- THD+N +15dB band boost	<0.15% (1kHz,0dBu)
- Input impedance	10kohm
- Output impedance	< 100ohm
- Max signal level	+24dBu
- Crosstalk	< -100dB
- THD (MID switch)	1%
- THD (HIGH switch)	2.5%
- Power consumption	150mA per rail
- Dimensions	76x133x158mm
- Warranty	2 years
`
  }, {
    label: "Notes",
    markdown: `
Unit needs to warm up for about 5 minutes before usage as its temperature may affect some internal variables – like frequencies of each band, and very slightly gain and Q settings.  
Each band can boost or cut in configurable gain mode (It affects also OUTPUT control):  
- 15dB boost or cut in 0.25 dB steps,
- 5dB boost or cut in 0.083 dB steps,
Each frequency control has predefined amount of steps in logarithmic scale:  
- HF frequency – 256 steps,
- HMF frequency – 256 steps,
- LMF frequency – 256 steps,
- LF frequency – 200 steps,
- HPF frequency – 128 steps.
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://wesaudio.com/product/_hyperion-eclipse/" target="_blank">https://wesaudio.com/product/_hyperion-eclipse/</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.EQ, HASHTAGS.DUAL_SLOT, HASHTAGS.NEXT_GEN, HASHTAGS.MIXING, HASHTAGS.MASTERING, HASHTAGS.LIVE_SOUND, HASHTAGS.API_500],
    manufacturer: BRAND.WESAUDIO,
  },
  videos: [
    "https://www.youtube.com/embed/H38wYd7DgKw?si=c1Ji1Civ-QPwFZ_A",
    "https://www.youtube.com/embed/5VkpLj73_us?si=7MPGUFuPdz_Y0Yh9",
    "https://www.youtube.com/embed/gDgN66CNDnc",
    "https://www.youtube.com/embed/h2mNYpASiJg"
  ]
}