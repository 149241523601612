import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const ngbuscomp: IProductData = {
  id: "wesaudio-ngbuscomp",
  name: "WesAudio _NGBusComp",
  description: "Next generation bus compressor with digital recall",
  images: ["/img/wesaudio_ngbuscomp_front.png", "/img/wesaudio_ngbuscomp_back.png"],
  details: [{
    label: "Description",
    markdown: `
Next generation bus compressor with digital recall with recall.  

Legendary sound of Stereo Bus compressor has been true companion on countless recordings since 40 years.  

Often recognized as “mixbus glue”, this particular compression sound have become industry standard.  

Now, we improve this design creating whole new level of versitality with unbeliveble modern DAW integration –

We present ngBusComp – fully analog, dual mono, stereo, mid-side compressor, with digital recall.  
`
  }, {
    label: "Key Features",
    markdown: `
### ngBusComp fully analog, dual mono, stereo, mid-side compressor.
- 100% analog device with +26dBu of headroom
- Dual mono and stereo compression
- Mid-side mode
- Two selectable output stages
- IRON PAD – passive attenuator enabled in IRON mode
- Flexible DAW/Live plug-in control for instant recall
- Controllable thorugh standalone WesAudio application
- MIX KNOB FOR PARALLEL COMPRESSION
- Detailed metering for both channels
- THD – Proprietary THD with precise variable control
- Side chain filters
`
  }, {
    label: "Specifications",
    markdown: `
- THD+N < 0.03% (1kHz,0dBu)	at 0dBu < 0.006%
- Frequency response	0-300kHz (-3dB)
- Input impedance	>10kohm
- Output impedance	< 50ohm
- Crosstalk	> 90dB
- Max input level	+ 26dBu
- Max output level	+ 26dBu
- Dynamic range	> 120dB
- Attack	0.1, 0.3, 1,3, 10, 30 (ms)
- Release	0.1, 0.3, 0.6, 0.9, 1.2, Auto (s)
- Ratio	1.5, 2, 4, 10, INF
- Sidechain Filter	60, 90, 150, T1, T2
- THD	0% to 8%
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://wesaudio.com/product/ngbuscomp/" target="_blank">https://wesaudio.com/product/ngbuscomp/</a>
- <a to="https://wesaudio.com/uploads/manuals/WesAudio_ngbuscomp_manual.pdf" target="_blank">https://wesaudio.com/uploads/manuals/WesAudio_ngbuscomp_manual.pdf</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.COMPRESSOR, HASHTAGS.NEXT_GEN, HASHTAGS.MASTERING, HASHTAGS.MIXING, HASHTAGS.LIVE_SOUND, HASHTAGS.RACK, HASHTAGS.THREE_U],
    manufacturer: BRAND.WESAUDIO,
  },
  videos: [
    "https://www.youtube.com/embed/clpxr7KvGEk",
    "https://www.youtube.com/embed/9Ym5sjHPrX4",
    "https://www.youtube.com/embed/tRt3KJz8-dA",
    "https://www.youtube.com/embed/vpzkKdkdm68"
  ]
}