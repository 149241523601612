import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const mikeE: IProductData = {
  id: "empirical-el9-mike-e",
  name: "Empirical Labs Mike-E EL9",
  description: "Modern Digitally Controlled Microphone Preamp.",
  images: ["/img/empirical_mikee_front.png", "/img/empirical_mikee_left.png", "/img/empirical_mikee_right.png", "/img/empirical_mikee_back.png"],
  details: [{
    label: "Description",
    markdown: `
[See the TwinPak.](/products/empirical-twinpak)  
[See the TrakPak.](/products/empirical-trakpak)  

When is a mic-pre more than a mic-pre? When it gets out of the way of the sound of your microphone and lets the compressor / hi frequency “saturation” circuits do all the heavy lifting of the tonal shaping of the sound you’re recording or mixing.   

The Empirical Labs “Mike-E” is exactly that tool!! The microphone amplifier has far greater dynamic range capability than most microphones found in the vast majority of the world’s recording facilities.    
The result of this increased dynamic range is that the “Mike-E” has the ability to act like a “straight wire with gain”.    
The same clarity and definition hold true for the ultra-clean “DI” (Direct Injection) input as well… until you engage the CompSat.  

Though the pre-amp employs an input transformer, the noise floor of the microphone amplifier is so low, undesirable noise is difficult to bring up even with the use of extreme compression settings.  
The best part is that pre-amps input is digitally controlled in 5db increments for complete repeatability without the normal “flakiness” which can be associated with pots and stepped attenuators over time.  

Speaking of “extreme compression”; the “Distressor” like compression circuit in the “CompSat™” section of the “Mike-E” can be used to wild extremes, or as subtly as the user desires.   
Featuring 5 distinct compression ratios (including the ever popular “Nuke” setting), the unit features absolutely repeatable attack and release time constants while you control the quantity of the desired compression via the “Drive” control.    
Two “CompSat™” sections can be linked for stereo recording / mixing with ease, and should you decide you would like a completely radical “parallel compression” effect, there is no need to waste an additional fader as the “Mike-E” has the ability to mix the “wet” and “dry” elements of the signal.  

The “Mike-E”’s “CompSat™” section doesn’t end there (in fact, its just the beginning!!).  
In addition to the entirely resettable compression chain is the “Saturator” section and the “Emphasis” section, which is where more real magic occurs.  

The “Saturator” is a multi-stage “soft clipping” circuit that imparts odd and even order harmonic distortions similar to those usually associated with triode tube circuits.  
As the level increases a second unique “clip circuit” employs Germanium transistors in order to more severely flatten the peaks (which creates another tonal texture unique to Empirical Labs products).  

The “Emphasis” element is a two-stage circuit that boosts and soft clips the high frequency content of the audio before the “CompSat™” circuit, and then cuts this boosted high frequency content in a manner consistent with the use of “noise reduction” as was often applied to analog tape recorders in days gone by.  
“Emphasis”, working in a similar manner to analog noise reduction has the added benefit of improving the signal to noise ratio, the net result being a “hotter” and more “emotionally compelling” sound, if that is the direction the production team would like to explore.  

When you combine the “Mike-E” with a “Lil FrEQ” (called the [“TRAK PAK”](/products/empirical-trakpak)) the amount of tone shaping power is limited only by your imagination, as you will have the power at your fingertips to create any kind of tone / texture you feel the music requires.  
`
  }, {
    label: "Specifications",
    markdown: `
- Frequency Response: 3 Hz to 200 kHz on (No CompSat). CompSat is 3Hz - 150Khz. Optional Transformer output is 6Hz - 80kHz.
- Signal to Noise: 130 dB signal to Noise at 40dB gain. Maximum output is +28dBu.
- Distortion: Rangers between .0006% and 15% depending on mode and settings.
- I/O: Transformer coupled Input, Active and Transformer Outputs available. High quality audio caps used internally.
- Impedance: Input Impedance is above 600 Ohms at all frequencies, transformer coupled. Output impedance is less than 38 ohms. Instrument in Impedance is 332K Ohms.
- Attack/Decay Ranges: .9 - 70mS attack. .15 - 1 Sec.
- Power Consumption: 15 Watts Max.
- Metal Chassis: Single height 1.75" high, 10" deep, 19" wide
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://www.empiricallabs.com/mike-e/" target="_blank">https://www.empiricallabs.com/mike-e/</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.MICPRE, HASHTAGS.RACK, HASHTAGS.ONE_U, HASHTAGS.RECORDING, HASHTAGS.MIXING, HASHTAGS.LIVE_SOUND],
    manufacturer: BRAND.EMPIRICAL_LABS,
  },
  videos: ["https://www.youtube.com/embed/HiUdgEkYrSM", "https://www.youtube.com/embed/1dYGvUl1Pl0", "https://www.youtube.com/embed/mUCSzCWduHg"]
}