
import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const sweetTenRack: IProductData = {
  id: "purple-audio-sweettenrack",
  name: "Purple Audio Sweet Ten Rack",
  description: "Tons of functions + High Quality = The Sweet Ten",
  specialOrder: true,
  images: ["/img/purpleaudio_sweetten_front.png", "/img/purpleaudio_sweetten_back.png", "/img/purpleaudio_sweetten_details.png"],
  details: [{
    label: "Description",
    markdown: `
Many manufacturers, including past Purple Audio prototypes, use linear power supplies for their racks.  
Traditional wisdom holds that linear power supplies are ideal for audio and generate less noise and interference.  
However, in the case of a 10 slot series 500 rack, even a well designed linear power supply generates a substantial magnetic hum field and far too much heat to allow for internal mounting.  
This problem is caused in part by the wide variation of line voltage across the world.  

With the advancement of switching power supplies it is now possible to have a switcher that does not compromise audio performance and delivers or exceeds all specified current needs.  
We have spent years perfecting just such a power supply.  
We have comparatively tested all of our, and many of our competitors, modules at full bandwidth (10Hz – 500kHz) on lab quality over built bench supplies to make sure that the Sweet Ten is not compromising audio performance in any way.  
Simply put, your 500 series modules will perform at their highest level in our rack.  
Audio performance improvements are considerable.

The Sweet Ten top, side, and back panels are finished in trivalent yellow chromate cold rolled steel instead of painted steel for improved grounding and shielding.  
Our ground plane motherboards provide additional grounding.  
Additionally, the rack motherboard has frequency bypass capacitors at each slot to improve filtering, especially for modules with DC/DC converters.  

Functionally, the Sweet Ten offers 2 inputs and 2 outputs per slot.  
Many of our modules already take advantage of second output and our Moiyn mixer module will use the second input to allow for stereo balanced source injection.  
Future uses of these connectors include fader loops on future modules as well as a few surprises!  
`
  }, {
    label: "Features",
    markdown: `
- Ten 500 Series spaces for your favorite modules
- Two inputs and Two outputs per slot (one XLR and one TRS in/out)
- Made from zinc plate cold rolled steel for improved grounding and shielding.
- Made with a Purple Audio designed switching power amp to ensure clean sound through all of your modules.
- Use with our Moiyn mixer module in slot 9 to turn this rack into a portable console!
`
  }, {
    label: "Specifications",
    markdown: `
- Dimensions: 19″ W x 5.25″ H x 8.25″ D
- Shipping Weight: 13 lbs ~ 6 kg
- Shipping Dimensions: 22″x14″x12″ 56cm x 36cm x 30cm
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="http://www.purpleaudio.com/products/sweet-ten-rack/" target="_blank">http://www.purpleaudio.com/products/sweet-ten-rack/</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.CASES, HASHTAGS.RACK, HASHTAGS.THREE_U, HASHTAGS.LIVE_SOUND, HASHTAGS.API_500],
    manufacturer: BRAND.PURPLE_AUDIO,
  },
  videos: ["https://www.youtube.com/embed/C0LahmBkX6M"]
}