
import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const cansII: IProductData = {
  id: "purple-audio-cans-ii",
  name: "Purple Audio 5H1 Cans II",
  description: "Discrete Stereo Headphone Amp and Control Room Preamplifier",
  specialOrder: true,
  images: ["/img/purpleaudio_cansii_front.png", "/img/purpleaudio_cansii_left.png"],
  details: [{
    label: "Description",
    markdown: `
Designed to work in standard “500” racks.

The Cans II Headphone and Control Room preamplifier is a great addition to your Sweet Ten Rack or any 500 series rack.  
This module gives you all of the control of a console master section in a small sleek design.  
The Cans II also give you the ability to quickly and easily check phase and width of mixes without extra DAW routing or plugins bogging down your CPU.  

The Cans II also can be used for headphone amplification in a live room or control room setting, giving you ways to make use of this module in any area of your small or large format studio.  

The Cans II provides excellent sound quality and control to any set of headphones or monitors in any and all applications.  
`
  }, {
    label: "Features",
    markdown: `
- Uses a KDJ6 op amp for mono and  KDJ5 op amps for outputs.
- Discrete signal path
- Capable of driving the most demanding phones
- 10k Ohm Transformer balanced input
- Hi-Z input will not load sources when multing
- 15dBu Gain
- Individual channel cuts
- Channel swap
- Channel sum to mono
- Precision matched stereo level control
- Ships with XLRF to XLRF adaptor for stereo input
- Unbalanced stereo front panel 1/4″ TRS output
- Second stereo balanced input on rear 1/4″ on Purple Sweet Ten rack (switchable via front panel toggle)
- Current consumption: 70mA
- Over Current Resettable Fused (prevents any module problem from affecting your rack)
- ROHS – Lead Free
In a sweet ten rack, the Cans II has EITHER two stereo balanced inputs OR a secondary rear panel output using the OUT 2/ OPT out jack.  
Set the jumpers as below to enable input or output, unit ships as secondary input enabled.
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="http://www.purpleaudio.com/products/cans/" target="_blank">http://www.purpleaudio.com/products/cans/</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.MIXING, HASHTAGS.RECORDING, HASHTAGS.LIVE_SOUND, HASHTAGS.SINGLE_SLOT, HASHTAGS.API_500],
    manufacturer: BRAND.PURPLE_AUDIO,
  }
}