import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const mimas: IProductData = {
  id: "wesaudio-mimas",
  name: "WesAudio _Mimas",
  description: "1176 – style analog compressor with digital recall",
  images: ["/img/wesaudio_mimas_front.png", "/img/wesaudio_mimas_back.png"],
  details: [{
    label: "Description",
    markdown: `
1176 – style analog compressor with digital recall  

Since year 1968 this style of compression dominated hundreds of recordings and to this day it is most commonly used signal processor in the industry.  

Lightning-fast attack and release times became main companions of audio engineers.  

WesAudio proudly presents _MIMAS – ng500 series implementation of legendary FET compressor.  

This time along with classic analog sound You will receive most demanded feature in audio industry – DIGITAL RECALL.  
`
  }, {
    label: "Key Features",
    markdown: `
### _MIMAS NEW IMPLEMENTATION OF A LEGEND.
- _MIMAS – CARNHILL TRANSFORMERS are foundation of its sound.
- Full balanced (IN/OUT) analog device with +24dBu of headroom.
- FOUR RATIO SETTINGS AND LEGENDARY “ALL BUTTONS IN”.
- Interstage CARNHILL transformers
- SATURATION MODE ONE KNOB to add color and character to your tracks without using compression
- 500 SERIES and ng500 SERIES COMPATIBLE
- HARDWARE A/B
- TOTAL RECALL AND PLUGIN CONTROL
- ANALOG AUTOMATION
- Four touch sensitive encoders
`
  }, {
    label: "Specifications",
    markdown: `
- Attack time	20us – 800us
- Release time	50ms – 1100ms
- ompression ratio	4:1; 8:1; 12:1; 20:1; all button mode True Bypass
- SC High Pass filter	60,90,150Hz
- Output impedance	600ohm
- Frequency response	20Hz do 20kHz (+/- 1dB)
- Signal to noise ratio SNR	>83dB
- Power consumption	3W (100mA/+16V i 60mA/-16V)
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://wesaudio.com/product/_mimas/" target="_blank">https://wesaudio.com/product/_mimas/</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.COMPRESSOR, HASHTAGS.SINGLE_SLOT, HASHTAGS.NEXT_GEN, HASHTAGS.MIXING, HASHTAGS.LIVE_SOUND, HASHTAGS.API_500],
    manufacturer: BRAND.WESAUDIO,
  },
  videos: [
    "https://www.youtube.com/embed/Txyv5Rpy3IU", "https://www.youtube.com/embed/7M5LRAdAyD0"
  ]
}