
import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const mc77: IProductData = {
  id: "purple-audio-mc77",
  name: "Purple Audio MC77 Limiter",
  description: "The MC77 recreates the audio circuitry of the revision E 1176, using modern components matched to the original.",
  specialOrder: true,
  images: ["/img/purpleaudio_mc77_front.png", "/img/purpleaudio_mc77_inside.png", "/img/purpleaudio_mc77_back.png"],
  details: [{
    label: "Description",
    markdown: `
Starting in 1997 Purple started making the MC76, an 1176 type compressor base on the D & E version units. (MC is 1100 in roman numerals)   
At the time the 1176 was not available from any manufacturer.  

Coming from the repair background it was clear a better built, more road worthy unit could be build and sold new for less than the 20+ year old units that were coming through the shop.  
After another manufacture brought an 1176 clone on to the market Purple introduced the MC77.  

The MC77 added new features that were lacking from the original.  
Thousands of units later Purple keeps its parts and build quality high.  
Using parts that will last decades.  

Have a look inside and see for yourself.  
Toroidal power transformer, the best interlocking switches on the market, conductive plastic potentiometers.  
Quality for longevity.
`
  }, {
    label: "Inherited from the MC76",
    markdown: `
• Discrete Transistor Audio Path Electronics  
• Single Element Class A Output Amplifier  
• Transformer Balanced XLR Inputs and Outputs  
• Zener Shunt Regulated Audio Power Supply  
• Compression Ratios – 4:1, 8:1, 12:1 and 20:1  
• Fast Attack Time – 20 to 800 microseconds  
• Release Time – 50 milliseconds to 1.1 second  
• Gain of 45dB (full gain with no limiting)  
• Ruggedized Design – PCB stiffener, chassis mounts  
• Purple Anodized Aluminum Front Panel  
• 3 Year Warranty*  
`
  }, {
    label: "New on the MC77",
    markdown: `
• True Bypass via sealed relay w/front panel switch
• Improved stereo linking w/front panel switch
• Sidechain insert loop or key input w/front panel switch
• Buffered VU meter can monitor input or output level at +4dBu
• LED meter lights that don’t burn out
• Heavy gauge black cold rolled steel enclosure
• 155v/230v operation switchable from rear panel

The MC77 should be fitted with 1/4A slow-blow @ 110V, 1/8A Slow-blow @220V.  
1/4A slow-blow available from Mouser or Digikey in the US.
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="http://www.purpleaudio.com/products/mc77/" target="_blank">http://www.purpleaudio.com/products/mc77/</a>
- <a to="http://www.purpleaudio.com/product/PurpleMC77v34.pdf" target="_blank">Manual & Schematic</a>
- <a to="http://www.purpleaudio.com/product/PAMC77.PDF" target="_blank">Recall sheet</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.COMPRESSOR, HASHTAGS.MIXING, HASHTAGS.RECORDING, HASHTAGS.LIVE_SOUND, HASHTAGS.RACK, HASHTAGS.TWO_U],
    manufacturer: BRAND.PURPLE_AUDIO,
  },
  videos: ["https://www.youtube.com/embed/x1nr8JM5XSg", "https://www.youtube.com/embed/Zp95oCFEfCo"]
}