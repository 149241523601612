
import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const tav: IProductData = {
  id: "purple-audio-tav",
  name: "Purple Audio 5E2 Tav",
  description: "A ten band inductor based graphic EQ",
  specialOrder: true,
  images: ["/img/purpleaudio_tav_front.png", "/img/purpleaudio_tav_left.png"],
  details: [{
    label: "Description",
    markdown: `
Designed by Ed Anderson for Purple Audio.  

An illuminated pushbutton for relay true bypass (green) allows for easy comparison with unprocessed source material.  
A Tricolor LED meters the signal.  
The frequency response is +/- 0.25dB from 15Hz to 25kHz, and -3dB at 60kHz.  
A new take on the console graphic, with ten easy to use bands.  
Each band uses a high quality inductor and carefully selected caps to set the center frequency.  
At full boost and full cut the bands are narrow enough to still be useful individually, but at lower settings the bands widen out for more gentle shaping.  
Even at full boost and cut, the filters are shaped to be musical and useful.  

With specialized input and output transformers, the TAV has plenty of headroom, even at full boost.  
The entire circuit is discrete, with no electrolytic capacitors in the signal path, and no servo amps.  
With only two active stages, the current draw is low enough to work in any rack, and leave plenty of juice for other modules.  
The Tav uses one KDJ3 opamp and one KDJ4 opamp.  
`
  }, {
    label: "Features",
    markdown: `
- Each band boost or cut by 12dB
- Relay True Bypass
- Tricolor LED level meter
- Transformer balanced in and out
- Discrete signal path
- No electrolytic caps in signal path
- Current consumption: 60mA
- Over Current Resettable Fused (prevents any module problem from affecting your rack)
- ROHS – Lead Free
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="http://www.purpleaudio.com/products/tav/" target="_blank">http://www.purpleaudio.com/products/tav/</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.EQ, HASHTAGS.MIXING, HASHTAGS.RECORDING, HASHTAGS.LIVE_SOUND, HASHTAGS.SINGLE_SLOT, HASHTAGS.API_500],
    manufacturer: BRAND.PURPLE_AUDIO,
  },
  videos: ["https://www.youtube.com/embed/Nad8TS8BdSI"]
}