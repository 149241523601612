import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const trakPak: IProductData = {
  id: "empirical-trakpak",
  name: "Empirical Labs ELI TrakPak (Mike-E & Lil FrEQ)",
  description: "Includes one EL9 Mike-E mic pre & one ELQ Lil FrEQ, effectively creating an Empirical Labs channel strip.",
  images: ["/img/empirical_trakpak_front.png", "/img/empirical_trakpak_back.png"],
  details: [{
    label: "Description",
    markdown: `
[See also Mike-E](/products/empirical-el9-mike-e)   
[See also Lil FrEQ](/products/empirical-elq-lil-freq)    

Includes one EL9 Mike-E mic pre & one ELQ Lil FrEQ, effectively creating an Empirical Labs channel strip.  
`
  }, {
    label: "Mike-E Specifications",
    markdown: `
- Frequency Response: 3 Hz to 200 kHz on (No CompSat). CompSat is 3Hz - 150Khz. Optional Transformer output is 6Hz - 80kHz.
- Signal to Noise: 130 dB signal to Noise at 40dB gain. Maximum output is +28dBu.
- Distortion: Rangers between .0006% and 15% depending on mode and settings.
- I/O: Transformer coupled Input, Active and Transformer Outputs available. High quality audio caps used internally.
- Impedance: Input Impedance is above 600 Ohms at all frequencies, transformer coupled. Output impedance is less than 38 ohms. Instrument in Impedance is 332K Ohms.
- Attack/Decay Ranges: .9 - 70mS attack. .15 - 1 Sec.
- Power Consumption: 15 Watts Max.
- Metal Chassis: Single height 1.75" high, 10" deep, 19" wide
`
    }, {
      label: "Lil FrEQ Specifications",
      markdown: `
- Frequency Response: 5 HZ to 140 kHz on transformerless inputs and outputs. Transformer output 10 Hz to 110 kHz.
- Dynamic Range: 120 dB from maximum output (.1% clipping) to minimum output. Greater than 110 dB signal to noise.
- Distortion: Ranges between .00056% and .005% depending on mode and settings. DS section has THD < .005%.
- I/O: DC coupled inputs and outputs and one transformer coupled output.
- Dynamic Time Constraints: Attack is approximately .5 ms. Release .04 sec fixed internally.
- Power Consumption: 15 Watts Max.
- Metal Chassis: Single height 1.75" high, 10" deep, 19" wide.
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://www.empiricallabs.com/mike-e/" target="_blank">https://www.empiricallabs.com/mike-e/</a>
- <a to="https://www.empiricallabs.com/lilfreq/" target="_blank">https://www.empiricallabs.com/lilfreq/</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.MICPRE, HASHTAGS.EQ, HASHTAGS.DESSER, HASHTAGS.RACK, HASHTAGS.TWO_U, HASHTAGS.RECORDING, HASHTAGS.MIXING, HASHTAGS.LIVE_SOUND],
    manufacturer: BRAND.EMPIRICAL_LABS,
  }
}