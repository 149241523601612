import { HASHTAGS } from "../../type/EnumHashtags";
import { IResource } from "../../type/IResource";

export const why500: IResource = {
  id: "why-500-series-lunchboxes-in-2022",
  title: "Why 500 Series lunchboxes in 2022?",
  date: "2 Jan 2022",
  author: "2 Minute read",
  preview: "Why would you get a 500 series lunchbox in 2022? In this article, we talk about the advantages and reasons why you would want to own a 500 series module.",
  mdBody: `## **Why get a lunchbox?**  
To be able to answer this question, we have to take a quick history lesson.

## **A quick history**  
If you are not already accustomed to the name API, or Saul Walker, then let this be the start. 
Automated Processes Inc, aka API Audio was founded in 1968 by Saul Walker.
Usually the answer to the question "How do I get that classic American sound?", they were the company that introduced the concept of 500 series modules. 
Originally designed for their own line of consoles, they were part of the concept of modular building block system. 
They allowed console owners to switch out components such as the mic pre, dynamics and EQs.  

When 1985 came around, modular signal chain was a popular trend and API launched the lunchbox.
This allowed engineers to bring with them their favourite 500 series modules wherever they went. 
The demand for lunchboxes and 500 series modules rose and API formed the [VPR Alliance](https://www.apiaudio.com/vpr_alliance.php) allowing the standardisation of 500 series modules.
This paved the way for many other manufacturers to start developing their own standardised 500 series modules.

## **So why do should I get one?**
Here are some reasons why you would consider 500 series modules:
- **Analog color**  
*In our current day and age, we have the advantage of software and going "in the box".*
*With software and plugins giving really good quality and bang for buck, it can be hard to justify the price for analog gear.*
*However 500 series modules are relatively more affordable compared to rack mounted outboard gear.*
*And they still give you that analog color and performance which not all plugins can't be fully replicated.*
*There are many other points to discuss on this which we'll leave it to another article.*

- **Quality in a small form factor**  
*Rack mounted outboard gear can take 1U or up to 3U of space.*
*Whereas a 10 slot API rack takes up to 3U of space.*  
*That's 10 modules compared to 3 outboard gear!*
*It's not about the size, but the quality of the components and the design of the module!*
*Granted there are certain limitations, in terms of power and headroom, however that is important and makes a difference in a few use specific cases.*

- **Convenience**
*There are smaller 3 slot lunchboxes or even 10 slots with handles. Certainly easy to bring along your familiar gear to different sites.*
*Power is often overlooked, but when you have 10 outboard gear, you also have 10 power plugs.*
*If you do not have a power conditioner or sequencer, that's 10 power plugs you have to plug in! Compared to 1 with the lunchbox that takes care of the power supply.*

- **Versatility**  
*Whether you're planning to add that analog color while mastering, or wanting recording your parts with quality components, you can use your same 500 series modules for all these cases.*
*From recording, to mixing, all the way to mastering the final mix.*
*You can also set them up in your studio and take them out to a recording day or even use them in the live environment for live events!*

- **Cost**  
*The cost that you will be looking at will definitely be cheaper compared to rack mounted gear.*

- **Continued Development**  
*Continued advancements in 500 series gear. New advancements are being made such as integrating audio interfaces into cases and the modules themselves.*
*This allows for direct interfacing of the hardware modules in your DAW!*
*This also helps to tackle the tideous process of manual recall of hardware settings :)*
*We are coming into an age of of hybrid usage of digital and physcial hardware. We will continue to see exciting advancements that will give us the best of both analog and digital.*

So if you're convinced, take a look through our catalogue to get started :)
  `,
  metadata: {
    hashtags: [HASHTAGS.STARTING_OUT]
  }
}