import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const ssl500sixchannel: IProductData = {
  id: "ssl-500-six-channel",
  name: "SSL 500 SIX Channel",
  description: "SSL 500 series channel",
  images: ["/img/ssl_500_series_six_channel.png"],
  details: [{
    label: "Description",
    markdown: `
SiX Channel Strip (SiX CH) is a single-width 500 Series channel strip using the SuperAnalogueTM channel processing features from SSL’s SiX console including the Mic-pre, low and high frequency EQ, as well as the single knob compressor.`
  }, {
    label: "Key Features",
    markdown: `
- SSL SuperAnalogueTM  mic pre
- +48V phantom power
- Phase invert switch
- 5 segment LED meter in dBu
- Switched 12 dB/oct, 75 Hz High Pass Filter (HPF)
- Classic SSL HF and LF EQ
- Switch from shelf to bell curve
- Single knob Channel Compressor with variable Threshold.
- Front Panel TRS Line Input with 1MΩ Hi-Z switch.
- Dedicated output Trim
    `
  }, {
    label: "Specifications",
    markdown: `
- Module signal: Mono
- Pre-Amp Gain: Variable from +6 to +72 dB (Mic), -3 to +63dB (Line)
- Mic Pre EIN: Typically 129dB
- THD+N: <0.0015%
- CMRR: >80dB (Mic), >70dB (Line)
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://www.solidstatelogic.com/products/six-ch" target="_blank">https://www.solidstatelogic.com/products/six-ch</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.MICPRE, HASHTAGS.SINGLE_SLOT, HASHTAGS.STARTING_OUT, HASHTAGS.RECORDING, HASHTAGS.API_500],
    manufacturer: BRAND.SSL,
  }
}