import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const TG2500: IProductData = {
  id: "tg2-500",
  name: "Chandler Limited TG2-500 Pre Amp",
  description: "Pre Amp",
  images: ["/img/chandler_limited_tg2_500.png"],
  details: [{
    label: "Description",
    markdown: `
The Chandler Limited TG2-500 pre-amp is a recreation of the rare EMI TG12428 pre amp used in EMI/Abbey Road Studios recording and mastering consoles from the late 60s and early 70s.  
The sound of the EMI/Abbey Road Studios equipment is world renowned, and imparts a unique and pleasing sonic character as heard on many famous recordings done at Abbey Road including The Beatles’ “Abbey Road” and Pink Floyd’s “Dark Side of the Moon” albums.  
For the first time ever, the legendary TG2 pre-amp sound is available in 500 Series format.  
The TG2-500 microphone pre-amplifier features the same discrete, transformer-balanced amplifiers as Chandler Limited’s TG series: TG2 Pre Amp/DI, TG Microphone Cassette, TG12411 Channel (TG Channel MKII), and TG1 Limiter.  
We’ve taken the microphone pre-amplifier and line amplifier from our highly acclaimed TG2 Pre Amp/DI and packaged it for the industry standard 500 Series format, while retaining the sound of Chandler Limited’s TG2 Pre Amp/DI.  
The aural quality has often been characterized as a creamy, smooth tone with a surprisingly open, clear top-end. The sound, as explained by many users, has "warmth" and punch, but with a "beautiful" expanded top end.  
In fact, the TG2-500 microphone preamplifier does have a small rise in its top-end frequency response and some warmth inducing distortion, which contributes to its sound.  
Bring the sound of the Chandler Limited TG2-500 pre amp and EMI/Abbey Road Studios home to your 500 Series rack today.  
Read more about the Chandler Limited TG2-500 pre amp in this press release.  
Abbey Road Studios, EMI and TG and their associated logos are trade marks of EMI (IP) Limited.  
`
  }, {
    label: "Specifications",
    markdown: `
- Circuit:	Discrete, Transistor, Transformer Balanced I/O
- Total Gain:	+60 dB
- Coarse Gain:	+20 — +50 dB (stepped, 5 dB)
- Fine Gain:	±10 dB (variable)
- Input:	Mic/Line, switchable
- Input Impedance:	1200/300 Ω, switchable
- Phantom Power:	+48V, switchable
- Phase (Polarity):	0/180º, switchable
- I/O:	500 Series format (frame dependent)
- 500 Series format:	+/-16V
- Max. current draw:	+16V @ +110mA / -16V @ -80mA

  `
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://chandlerlimited.com/tg2-500-pre-amp/" target="_blank">https://chandlerlimited.com/tg2-500-pre-amp/</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.MICPRE, HASHTAGS.SINGLE_SLOT, HASHTAGS.API_500],
    manufacturer: BRAND.CHANDLER_LIMITED,
  }
}