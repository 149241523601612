
import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const beta76: IProductData = {
  id: "wesaudio-beta76",
  name: "WesAudio Beta76",
  description: "The element responsible for the gain reduction is a  FET transistor,  giving the effect of rapid and pure compression.",
  images: ["/img/wesaudio_beta76_front.png", "/img/wesaudio_beta76_back.png"],
  details: [{
    label: "Description",
    markdown: `
Beta76 FET compressor/limiter  

The introduction of several significant structural improvements against the original version.  
One of the most important changes is two mode switch – Modern / Vintage –  deciding  on the circuit of the desymmetrization of the input signal.  

Modern Mode – is an  electronic circuit, exactly the same as the previous version of the compressor and the Vintage mode – it’s a Carnhill input transformer.  
The result is a universal device with two different sound possibilities.  

A True Bypass switch was introduced, as well as a high-pass filter in the Compressor’s Side Chain path.  
There are three cutoff frequencies – 60, 90, 150 Hz – thanks to which Beta76 is less sensitive to low frequencies.  
The filter is particularly beneficial to the operation of two compressors in a stereo pair, which are easily connected with a  prepared Jack-Jack cable.  

The entire system operates in class A.  
The element responsible for the gain reduction is a  FET transistor,  giving the effect of rapid and pure compression.  

There is a high quality Carnhill audio transformer on the output favourably affecting the final sound of the compressor.  
The housing is made from high quality brushed stainless steel, whereas  the panel  is 4mm brushed aluminium.  
`
  }, {
    label: "Specifications",
    markdown: `
- Attack time	20us – 800us
- Release time	50ms – 1100ms
- Compression ratio	4:1, 8:1, 12:1, 20:1
- True	Bypass
- SC High Pass filter	60, 90, 150 Hz
- Output impedance	600ohm
- Frequency response	20Hz-20kHz (+/- 1dB)
- Signal to noise ratio SNR	>83dB
- Balanced output and input	(XLR Neutrik)
- Case Rack	2U
- Dimensions	88x483x235mm
- Weight	6kg
- Warranty	3 years
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://wesaudio.com/product/beta76/" target="_blank">https://wesaudio.com/product/beta76/</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.COMPRESSOR, HASHTAGS.TWO_U, HASHTAGS.MIXING, HASHTAGS.RECORDING, HASHTAGS.LIVE_SOUND, HASHTAGS.RACK],
    manufacturer: BRAND.WESAUDIO,
  }
}