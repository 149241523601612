import { IProductData } from "../../../type/IProductData";
import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";

export const supercarrierII: IProductData = {
  id: "wesaudio-supercarrier-ii",
  name: "WesAudio Supercarrier II",
  description: "11 slot 500 series frame fully compatible with API standard",
  images: ["/img/wesaudio_supercarrier_ii_front.png", "/img/wesaudio_supercarrier_ii_back.png"],
  details: [{
    label: "Description",
    markdown: `
11 slot 500 series frame fully compatible with API standard.  

Supercarrier II is a second generation of our very popular Supercarrier I – 500 series standardized chassis.  
It features 11 slots with innovative ISP technology.  

ISP – Independent Slot Power – is a solution which makes a huge breakthrough in 500 series powering system, it allows best accommodation for even most demanding 500 series modules.  

So what it is all about ? The answer is very simple – based on the ISP technology, each slot is individually supplied with linear power, causing each module to work in totally isolated, and independent way.  

To say it simple, it is like each of 11 modules would work in separate 500 series frames, what makes it ideal environment for your beloved hardware – without any interference, noise, pops or clicks.  

To separate each slot even more, we introduced a tantalum caps for each slot to clean the whole frequency spectrum from any other unwanted noise.  
The chassis itself, is powered by big 100W toroid external supply.  
`
  }, {
    label: "Key Features",
    markdown: `
### Supercarrier II
- 11 slots
- ISP (Independent Slot Power) technology
- Tantalum caps on each slot
- XLR sockets in/out for each slot
- DB-25 socket for first 8 slots
- Internal stereo link switches
- Linear power supply capable to deliver 5A of power
- Power mode switch – 115V/230V
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://wesaudio.com/product/supercarrier-ii/" target="_blank">https://wesaudio.com/product/supercarrier-ii/</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.CASES, HASHTAGS.STARTING_OUT, HASHTAGS.RACK, HASHTAGS.LIVE_SOUND, HASHTAGS.THREE_U, HASHTAGS.API_500],
    manufacturer: BRAND.WESAUDIO,
  }
}