import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: string) => augmentColor({ color: { main: mainColor } });

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      // main: '#556cd6',
      main: '#0B0B45',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    turquoise: createColor("#40E0D0"),
    yellow: createColor("#FFD814"),
    orange: createColor("#FFA41C"),
  },
  typography: {
    h1: {
      fontSize: "3rem",
      '@media (min-width:600px)': {
        fontSize: '5rem',
      },
      color: "#0B0B45"
    },
    h2: {
      fontSize: "2rem",
      '@media (min-width:600px)': {
        fontSize: '3.5rem',
      },
      color: "#0B0B45",
    },
    h3: {
      color: "#0B0B45"
    },
    h4: {
      fontSize: "1.5rem",
      '@media (min-wdith:600px)': {
          fontSize: "2.125rem",
      },
      color: "#0B0B45"
    },
    h5: {
      color: "#0B0B45"
    },
    h6: {
      color: "#0B0B45"
    },
    subtitle1: {
      color: "#0B0B45"
    },
    subtitle2: {
      color: "#0B0B45"
    },
    body1: {
      color: "#0B0B45"
    },
    body2: {
      color: "#0B0B45"
    }
  },
  shape: {
    borderRadius: 5,
  }
});

export default theme;
