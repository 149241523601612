import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const mytheq500: IProductData = {
  id: "tegeler-mytheq500",
  name: "Tegeler Audio Manufaktur MythEQ 500",
  description: "500er Magical Equalizer",
  images: ["/img/tegeler-mytheq-front.jpg", "/img/tegeler-mytheq-side.jpg"],
  details: [{
    label: "Description",
    markdown: `
## How to come up with a new equalizer without replacing the previous one?
That was the main question on our minds.
The Classic Equalizer EQP-1 scores with its nice warm sound.
So it was clear: we needed to create a Yang to our Yin EQ.
A device that works in a cleaner way, but is also capable of biting – and still retaining the Tegeler character. A device that you can use to get a warm and melodious sound, as well as drastic and aggressive EQ settings.
`}, {
  label: "Features",
  markdown: `
- API-500 series mono equalizer
- Toggle switch: in/bypass
- High band shelf filter: 3kHz, 3.3kHz, 3.7kHz, 5.5kHz, 8.5kHz, 10kHz
- Upper midrange bell filter: 1kHz, 1.5kHz, 2.5kHz, 4.5kHz, 12kHz, 16kHz
- Lower midrange bell filter: 80Hz, 120Hz, 190Hz, 330Hz, 900Hz, 1.3kHz
- Low band shelf filter: 50Hz, 55Hz, 65Hz, 100Hz, 300Hz, 450Hz
- Maximum boost/cut: +-15dB
`
  }, {
    label: "Specifications",
    markdown: `
- Frequency response: 20Hz-40kHz
- Maximum input level: +20dBu
- Input impedance: ≥2.4kΩ
- Output impedance: <600Ω
- Maximum output level: +21dBu
- Dynamic range: ≥100dB
- Supply voltage: +-16V
- Fuse: -
- Maximum current consumption: 80mA
- Size: standard API 500 module size
- Weight: 540g
    `
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://www.tegeler.com/MythEQ500/Index" target="_blank">https://www.tegeler.com/MythEQ500/Index</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.EQ, HASHTAGS.SINGLE_SLOT, HASHTAGS.API_500, HASHTAGS.LIVE_SOUND, HASHTAGS.MIXING, HASHTAGS.MASTERING],
    manufacturer: BRAND.TEGELER_AUDIO,
  },
  videos: []
}