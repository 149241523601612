import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const cremeRC: IProductData = {
  id: "tegeler-creme-rc",
  name: "Tegeler Audio Manufaktur Creme RC",
  description: "Bus Compressor and Mastering Equalizer with Remote Control",
  images: ["/img/tegeler-cremerc-front.jpeg", "/img/tegeler-cremerc-side.jpeg", "/img/tegeler-cremerc-back.jpeg"],
  details: [{
    label: "Description",
    markdown: `
End your inner battle between digital and analog.

Our Creme has become a modern classic - VCA compression in uncompromising sound quality, bundled with sound refinement at the highest level thanks to passive EQ based on the proven Pultec principle. The combination you've been looking for to add that decisive nuance to your mix or mastering.

With the Creme RC, we are taking this recipe for success to the next level, because it combines two worlds - analog sound and digital control - into a perfect symbiosis. analog sound and digital control - into a perfect symbiosis. Thanks to motorized potentiometers you save the annoying work of recalling your settings by hand, if you have to edit a session again afterwards. As soon as you open your project, the corresponding Creme RC plugin ensures that all knobs automatically turn to the correct position. Automate individual parameters in the arrangement? No problem with the Creme RC.

Many successful mixing engineers have one thing in common: they use our Creme!

Even while mixing, the Creme gives you an idea with a slight treble and bass boost and a few dB of compression, how the mix will sound after mastering. This way you can easily find the right volume balance for your individual sounds, especially the voices. The order of EQ and compressor is also switchable, making the Creme RC an always flexible helper in mixing and mastering.
`
  }, {
    label: "Creme Mode",
    markdown: `
The Creme RC's compressor brings yet another new cherry on top. For even more kicking bass and fresher treble, we have used our experience from the Schwerkraftmaschine and designed the Creme mode together with some Berlin artists.

In addition to the classic auto mode, there is hereby an alternative to the intelligent release automatic. The Creme mode reacts a little faster and more aggressive and is thus perfectly suited to the requirements of modern music styles.
`}, {
  label: "Equalizer Features",
  markdown: `
- Passive EQ (Pultec style)
- Bass boost frequencies: 20-30-60-100+140+200 Hz
- Bass boost level: 0-5 dB switchable
- High boost frequencies: 10-12-16-18-20-24 kHz
- High boost level: 0-5 dB switchable
`
  }, {
    label: "Compressor Features",
    markdown: `
- Lowcut filter: 60-120 Hz
- Attack: 0,1-0,3-1-3-10-30 ms
- Release: 100-300-600 ms-1,2s-Auto
- Ratio: 1,5-2-4-10
- Rasted potentiometers
- Freqency responce: 20 - 40.000 Hz +/- 1,5 dB
- Balanced inputs and outputs
- Internal power supply with toroidal transformer
- Height: 88,4 mm (2U), width: 483 mm, depth: 250 mm
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://www.tegeler.com/CremeRC/Index" target="_blank">https://www.tegeler.com/CremeRC/Index</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.COMPRESSOR, HASHTAGS.EQ, HASHTAGS.RACK, HASHTAGS.TWO_U, HASHTAGS.RECORDING, HASHTAGS.MASTERING, HASHTAGS.MIXING, HASHTAGS.LIVE_SOUND],
    manufacturer: BRAND.TEGELER_AUDIO,
  },
  videos: []
}