export enum BRAND {
  WESAUDIO = "WesAudio",
  PURPLE_AUDIO = "Purple Audio",
  EMPIRICAL_LABS = "Empirical Labs",
  TEGELER_AUDIO = "Tegeler Audio",
  MOTHER = "Mother",
  SSL = "SSL",
  RADIAL = "Radial Engineering",
  WARM_AUDIO = "Warm Audio",
  CHANDLER_LIMITED = "Chandler Limited",
  PRISM_SOUND = "Prism Sound",
  BETTERMAKER = "Bettermaker",
  CRANBORNE = "Cranborne",
  GRACE_DESIGN = "Grace Design",
  LINDELL_AUDIO = "Lindell Audio",
  SHADOWHILLS = "Shadow Hills Industries",
}
