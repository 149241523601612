import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const phoebe: IProductData = {
  id: "wesaudio-phoebe",
  name: "WesAudio _Phoebe",
  description: "Class A Microphone Preamp with digital recall",
  images: ["/img/wesaudio_phoebe_front.png", "/img/wesaudio_phoebe_all.png", "/img/wesaudio_phoebe_plu.png"],
  details: [{
    label: "Description",
    markdown: `
The most noble character of microphone preamplifiers now fully integrated with digital workflow. 

Dual amp vintage design with most demanded modern features! 

NG500 fully analog, Class A microphone/line/instrument preamplifier with digital recall.
 
Audio signal is processed only via analog components!
`
  }, {
    label: "Features",
    markdown: `
### Main features summary:  
· 100% analog microphone/line preamp with +28dBu of headroom.  
· Class A fully analog - inspired by famous 1073 design based on dual amp topology.  
· Microphone gain up to 75dB.  
· Carnhill Transformers (input and output).  
· Output signal metering on the front panel with clip detection.  
· Phantom power and phase invert control.  
· Switchable impedance input (1200/300Ohm).  
· All controls/analog settings are fully manageable via the WesAudio GCon DAW plug-in.  
· IRON PAD - passive output attenuation circuit with 1 dB step (after the output transformer).  
· Front panel XLR/TRS socket which supports - HiZ instrument input (TRS), line input (XLR), microphone input (XLR).  
· Recallable signal source to be either back XLR Socket, or front panel XLR/TRS socket.  
· 500 series and ng500 (e.g. _TITAN) series compatible.  
· Total Recall and plugin control compatible with most DAWs.  
· Analog automation in DAW.  
### Other features:  
· Fully digitally controlled and isolated analog circuit.  
· Digital recall can be achieved by connecting the unit directly through the front panel mini USB socket, or using it inside _TITAN chassis.  
· Free software and firmware upgrades.  
`
  }, {
    label: "Specifications",
    markdown: `
· Line input impedance: 5.5k ohm bridging   
· Noise: unity gain (better than -100dbU) Max gain +75dB
(better than -70dbU)   
· EIN: better than -120dB   
· Freq response: +/- 0.5dB 20Hz-20kHz; -3dB at 50kHz   
· Distortion: 0.005% at 1kHz at +20dBu output   
· Max power consumption: 170mA(+16V); 100mA(-16V)   
· Dimensions : 38x133x145mm  
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://wesaudio.com/product/_phoebe/" target="_blank">https://wesaudio.com/product/_phoebe/</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.MICPRE, HASHTAGS.SINGLE_SLOT, HASHTAGS.NEXT_GEN, HASHTAGS.MASTERING, HASHTAGS.LIVE_SOUND, HASHTAGS.RECORDING, HASHTAGS.API_500],
    manufacturer: BRAND.WESAUDIO,
  },
  videos: [
    "https://www.youtube.com/embed/09T__6QIkGc",
    "https://www.youtube.com/embed/xS0n9Rk095E",
    "https://www.youtube.com/embed/4WnEs8iD3H4",
    "https://www.youtube.com/embed/Lhw2GmYJZUw",

  ]
}