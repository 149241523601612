import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const dione: IProductData = {
  id: "wesaudio-dione",
  name: "WesAudio _Dione",
  description: "Analog BUS Compressor with digital recall",
  images: ["/img/wesaudio_dione_front.jpg", "/img/wesaudio_dione_back.jpg"],
  details: [{
    label: "Description",
    markdown: `
Analog bus compressor with digital recall  

Legendary sound of Stereo Bus compressor has been true companion on countless recordings since 40 years.  

Often recognized as “mixbus glue”, this particular compression sound have become industry standard.  

We present _DIONE – fully analog, legendary VCA compressor with digital recall.  
`
  }, {
    label: "Key Features",
    markdown: `
### _DIONE stereo bus compressor  
- 100% analog device with +24dBu of headroom powered by VCA THAT 2181,
- MIX KNOB FOR PARALLEL COMPRESSION
- THD – harmonic distortion with two modes (Medium & High),
- SIDE CHAIN FILTERS – 3 high pass filters at 60,90 and 150 Hz, and 2 tilt filters which are special “curve” designed filters,
- FOUR RATIO SETTINGS – 1.5:1 and 2:1 for gentle mixbus processing, and 4:1 and 10:1 for large variety of applications,
- 500 SERIES and ng500 (e.g. _TITAN) SERIES COMPATIBLE,
- TOTAL RECALL AND PLUGIN CONTROL compatible with most DAWs.
- ANALOG AUTOMATION in DAW.
`
  }, {
    label: "Specifications",
    markdown: `
- Frequency response	20Hz-20kHz (0.1dB)
- THD+N (WET)	0.008% (1kHz,0dBu)
- THD+N (DRY)	0.004% (1kHz,0dBu)
- Input impedance	10kohm
- Output impedance	< 100ohm
- Max gain	21dB
- Crosstalk	< -90dB
- Attack	0.1, 0.3, 1, 3, 10, 30 (ms)
- Release	0.1, 0.3, 0.6, 0.9, 1.2, Auto (ms)
- Ratio	1.5, 2.4, 10
- SC Filter	60, 90, 150, T1, T2
- THD (MID switch)	1%
- THD (HIGH switch)	4%
- Power consumption	70mA/+16V and 50mA/-16V (per slot)
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://wesaudio.com/product/_dione/" target="_blank">https://wesaudio.com/product/_dione/</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.COMPRESSOR, HASHTAGS.DUAL_SLOT, HASHTAGS.NEXT_GEN, HASHTAGS.MASTERING, HASHTAGS.MIXING, HASHTAGS.LIVE_SOUND, HASHTAGS.API_500],
    manufacturer: BRAND.WESAUDIO,
  },
  videos: [
    "https://www.youtube.com/embed/7vL4-O1eRjI", "https://www.youtube.com/embed/e_1-_a-pJDk"
  ]
}