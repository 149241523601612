import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const distressorel8: IProductData = {
  id: "empirical-distressorel8",
  name: "Empirical Labs Distressor EL8",
  description: "Single Channel Distressor. Also available in stereo pair.",
  images: ["/img/empirical_distressorel8_front.png", "/img/empirical_distressorel8_left.png", "/img/empirical_distressorel8_right.png"],
  details: [{
    label: "Description",
    markdown: `
[See the stereo pair.](/products/empirical-distressorel8-pair)  

Over the last 20 years the EL-8 “DISTortion compRESSOR” has become synonymous with greatness.   
With well over 25,000 units in the field and working daily, no other compressor is as well known, or well-loved as the ELI-8 and ELI-8X.   
Pretty much every recording made in the last decade-plus has employed at least one, if not several Distressors during the production process, no other unit, from any other manufacturer, can make that claim without lying more than a little bit.   
  
The Distressor has the capability to run as clean as clean can be, or add a little “attitude” as the user determines is appropriate for the music on which they’re working.   
Two different “distortion” offerings are created in the analog domain while being controlled digitally for ease of use and repeatability.  

Those “distortion” settings are called DIST 2, and DIST 3.   
When the DIST 2 function is engaged the user will find that “even order” harmonic distortion is added to the signal in a very musical manner, much like when audio is run through a tube circuit that features Triode tubes.   
When the DIST 3 function is engaged the user will find a fair bit of that “even order” harmonic distortion along with a helping of “odd order” harmonic distortion (like what you’d get from a Pentode tube circuit), which makes the audio a tad brighter and airier in addition to the “thick” qualities of the “even order” harmonic distortion.  

Even if adding some distortion isn’t your thing, the compression ratios are intensely useable, and the setting called “NUKE” is perhaps one of the most unique settings on any compressor ever built at any time in human history.   
“NUKE” has the ability to turn an average drum set into something you might hear on a Led Zeppelin record.   
“NUKE” has the ability to drive a sound (especially when used with a “parallel compression” technique) so far into the listener’s face it just can’t be ignored.  

Just as the sound of the unit is exceptionally variable, so is the ability to shape the compression curves to the point where the sound will fit into a recording or mix with ease, giving you one less thing to worry about!!  

Features include: Easy to read/understand nomenclature; distortion indication lights; musically intelligent ratio selections; built-in [accessible on the face panel] detector circuit side-chain EQ; XLR & ¼” inputs and outputs; and a true “hardwire” bypass for easy “before and after” comparisons.   
If you’ve gotten this far without nodding off to sleep, here’s a little bonus feature that isn’t in the manual!!   
If you don’t have the link cables inserted in the back of your Distressor and you hit the “link” button – it increases the noticeable effect of the DIST 2 and DIST 3 settings.   
If you’re the creative type, you can have an awful lot of fun with this formerly undisclosed “feature”.  
`
  }, {
    label: "Specifications",
    markdown: `
- Frequency Response: 2 Hz to 160 kHz in clean audio mode (+0, -3 dB). Response is shaped in distortion modes (Dist 2, Dist 3).  
- Dynamic Range: 110 dB from max. output to min. output in 1:1 mode. Greater than 100 dB signal to noise in distort 3 mode.  
- Distortion: Ranges between .02% and 20% depending on distortion mode and release times set on front panel.  
- I/O: DC Coupled input and outputs.  
- Time Constants: Attack range 50uS – 50mS. Release range .05 sec to 3.5 seconds, normal modes and up to 20 seconds in 10:1 opto mode. Time constants are dependent on ratio.  
- Power Consumption: 15 Watts Max.  
- Metal Chassis: Single height 1.75″ high, 10″ deep, 19″ wide.  
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://www.empiricallabs.com/distressor/" target="_blank">https://www.empiricallabs.com/distressor/</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.COMPRESSOR, HASHTAGS.RACK, HASHTAGS.ONE_U, HASHTAGS.RECORDING, HASHTAGS.MIXING, HASHTAGS.LIVE_SOUND],
    manufacturer: BRAND.EMPIRICAL_LABS,
  },
  videos: ["https://www.youtube.com/embed/W8x9UYMfdA4", "https://www.youtube.com/embed/_tResQVjWKM"]
}