import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const calypso: IProductData = {
  id: "wesaudio-calypso",
  name: "WesAudio _Calypso",
  description: "_CALYPSO is an audio interface designed to work in 500 series enclosure",
  images: ["/img/wesaudio_calypso_front.png", "/img/wesaudio_calypso_back.png"],
  details: [{
    label: "Description",
    markdown: `
NG500/500 series AD/DA converter  

_CALYPSO is an audio interface designed to work in 500 series enclosure.  

It features state of art 8 channels of AD/DA conversion and perfectly integrates with ng500 chassis – _TITAN, through I.A.C. connector.  

Besides its very convenient integration with ng500 chassis, it still can be used in standard 500 series enclosure using two DB25 connectors.  
`
  }, {
    label: "Key Features",
    markdown: `
### _CALYPSO AD/DA converter
- 24bit conversion
- Supported sample rates: 44.1kHz, 48kHz, 88.2kHz(SMUX), 96kHz(SMUX)
- Ultra low jitter clock
- Word clock IN/OUT
- Full integration with _TITAN via IAC connector (Just ADAT cables are need to route all signal to and from chassis)
- Two DB25 connectors allows integration with any 500 series chassis
- Two converter reference levels +8dBu/-10dBV
- LCD screen with channel metering
`
  }, {
    label: "Specifications",
    markdown: `
_Calypso specification:  
- Power consumption	150mA per rail
- Dimensions	76x133x158mm
- Warranty	2 years

Inputs (ADC):  
- Frequency response	20Hz-20kHzm +/- 0.1 dB
- THD+N(unweighted)	-108 dB (0.0004%)
- Dynamic range (unweighted)	116 dB
- Gain Settings	+4 dBu/-10 dBV (selectable per input)
- Max input level	(+4 dBu) +20 dBu
- Input Impedance	10K Ohms
- Connector type	DB25/IAC

Outputs (DAC):  
- Frequency response	20Hz-20kHzm +/- 0.1 dB
- THD+N(unweighted)	-105 dB (0.0005%)
- Dynamic range (unweighted)	115 dB
- Gain Settings	+4 dBu/-10 dBV (selectable per input)
- Max input level	(+4 dBu) +20 dBu
- Input Impedance	100 Ohms
- Connector type	DB25/IAC
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://wesaudio.com/product/_calypso/" target="_blank">https://wesaudio.com/product/_calypso/</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.INTERFACES, HASHTAGS.DUAL_SLOT, HASHTAGS.NEXT_GEN, HASHTAGS.RECORDING, HASHTAGS.LIVE_SOUND, HASHTAGS.API_500],
    manufacturer: BRAND.WESAUDIO,
  },
  videos: [
    "https://www.youtube.com/embed/ThWqUu5TJ7U"
  ]
}