
import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const lilpeqrM: IProductData = {
  id: "purple-audio-lilpeqrm",
  name: "Purple Audio 5E3 Lilpeqr M",
  description: "A two-band program EQ",
  specialOrder: true,
  images: ["/img/purpleaudio_lilpeqrm_front.png", "/img/purpleaudio_lilpeqrm_left.png"],
  details: [{
    label: "Description",
    markdown: `
Designed to work in standard 500 racks.  
Designed by Eisen Audio for Purple Audio.

The LILPEQr and LILPEQr-M* emulate the colorful 3D richness and useful responses of our favorite vintage tube (aka valve) program EQs (namely, the Lang PEQ-1 and the Klangfilm RZ062), but in a compact, solid-state format.  
The LILPEQr and LILPEQr-M will add air, weight, and glue to an already balanced mix.  
Additionally you can also use the LILPEQr and LILPEQr-M to immediately and intuitively alter the overall tone of an individual source.  
The LILPEQr is our take on the classic program (aka final mix) equalizer.  
It offers high and low frequency shelving bands, each with three carefully selected corner frequencies to choose from, and a switchable fader knob for overall level control.  

When the EQ is first switched in (center switch position – “IN” – LED off) your signal is injected passively into the tone network, directly following the input transformer.  
As with many classic program equalizers, this configuration allows for the ultimate in wide, lush, mix-buss response with extra “glue” and all that richness.  

Flip the switch one more click (upper switch position – “IN+Level” – LED red) to activate the buffered “Level” control.  
Placed in between the input transformer and EQ network, these discrete, class A buffers change the response ever so slightly, making the signal seem a bit tighter and more focused.  
This mode is more useful on individual sources within a mix.  

Relay true bypass (lower switch position – “BYP” – LED green) allows for easy before and after EQ comparisons, and a simple overall circuit path allows for wide bandwidth so that everything passes through… sounding better!  
In program mode (“IN”), with both EQ knobs centered, frequency response is +/- 0.5dBu from 20Hz to 20kHz, and extends below 10Hz and well beyond 120kHz before any drooping occurs.  

*The LILPEQr-M also includes stepped potentiometers for stereo matching and easy recall specifically in mastering situations.  
`
  }, {
    label: "Features",
    markdown: `
-Add air and weight to your mix with two EQ controls
-Control incoming volume level
-Crystal clear sound with tons of control
-Works in standard 500 series rack
-Lilpeqr-M includes stepped potentiometers for easy recall and stereo matching
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="http://www.purpleaudio.com/products/lilpeqr/" target="_blank">http://www.purpleaudio.com/products/lilpeqr/</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.EQ, HASHTAGS.MIXING, HASHTAGS.RECORDING, HASHTAGS.LIVE_SOUND, HASHTAGS.SINGLE_SLOT, HASHTAGS.API_500],
    manufacturer: BRAND.PURPLE_AUDIO,
  },
  videos: ["https://www.youtube.com/embed/wB74N-sfMrE", "https://www.youtube.com/embed/Zur3HfQUVp8"]
}