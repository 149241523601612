import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const sslGComp: IProductData = {
  id: "ssl-g-compressor",
  name: "SSL G Compressor",
  description: "Stereo Bus Compressor Module. The legendary compressor in a 2 slot 500 series form factor. Great for that glue that you're looking for BUS or main mix.",
  images: ["/img/ssl_500_g_comp.png"],
  details: [{
    label: "Description",
    markdown: `
The legendary sound of the SSL Stereo Bus Compressor has been glueing mixes together for 40+ years. Often imitated but never equaled, the centre section compressor from the 1980's Solid State Logic G-Series analogue console is an audio production legend. It is a simple unit with a simple purpose; it makes complete mixes sound bigger, with more power, punch and drive.`
  }, {
    label: "Key Features",
    markdown: `
- Legendary SSL G Series Stereo Bus Compressor
- THE glue and punch maker
- Additional compression ratio settings 1.5 / 3 / 10
- Renowned ‘Auto’ release
- HPF in sidechain switchable to 30Hz / 60Hz / 106Hz / 125Hz / 185Hz
- Sidechain In / Out switch
    `
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://www.solidstatelogic.com/products/stereo-bus-compressor-module" target="_blank">https://www.solidstatelogic.com/products/stereo-bus-compressor-module</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.COMPRESSOR, HASHTAGS.DUAL_SLOT, HASHTAGS.MASTERING, HASHTAGS.API_500],
    manufacturer: BRAND.SSL,
  }
}