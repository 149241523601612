
import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const moiyn: IProductData = {
  id: "purple-audio-moiyn",
  name: "Purple Audio 5S1 Moiyn",
  description: "All discrete 8 channel summing amp only for Purple Audio Sweet Ten Chassis.",
  specialOrder: true,
  images: ["/img/purpleaudio_moiyn_front.png", "/img/purpleaudio_moiyn_left.png"],
  details: [{
    label: "Description",
    markdown: `
Only for slot 9 of the Purple Audio Sweet Ten.  
The Moiyn is an incredible 500 Series module that can turn your Sweet Ten rack into an 8×2 mixer.  
While using this feature, you are even still able to use each of the first 8 module’s outputs like you normally would, with their own outputs into your DAW or Tape machine.  
You can set up the Moiyn 8×2 mixer module in 4 easy steps.  

Simply add any 500 Series modules to slots 1-8 of your Sweet Ten  
Make sure that the Moiyn is placed in slot 9 of the rack  
You can then assign each module (in 1-8) to either of the 2 outputs by using the buttons on the front of your Moiyn  
Finally, use the rotary knob to adjust output volume to your liking!  

Our custom hexifiliar output transformers provide two stereo transformer isolated outputs.  
One pair of outputs appears on the two output XLRs for slot 9.  
The Moiyn has internal jumpers to feed the second pair of outputs to slot 10.  
With the jumpers enabled, you can feed a Cans headphone amp in slot 10 automatically, without patching any cables in the back.  
Because of the Cans’ bridging transformer inputs, the pair of input XLRs on slot 10 can also be used as a second set of stereo outputs (with the input of the Cans multed to that output).  

The inputs for slot 9 (XLR IN/OPT IN) serves as a stereo input to the Moiyn mix buss for cascading multiple mixers.  
As an example: take one Sweet Ten with a Moiyn to do your drum submix and cascade that into a second Sweet Ten with a Moiyn for your whole mix.  
That’s a fully discrete 16 channel summing mixer in 6 RU, and you can pick almost any combination of 500 modules as the channel modules.  

The front panel of the Moiyn has two columns of eight illuminated push buttons, for left and right assign for each channel.  
Tricolor LEDs on either side of the slot 8 assigns show the mixbuss signal level.  
Below is a high quality 2-buss rotary master fader.  
The audio signal path of the Moiyn uses the same discrete opamps found in our other modules.  
The Moiyn uses two KDJ3 opamps and two KDJ4 opamps.  
`
  }, {
    label: "Features",
    markdown: `
- Discrete signal path (2 KDJ3 – Summing Amplifiers, 2 KDJ4 Output Amplifiers]
- Differential Summing Amplifier
- 15K drop on resisters
- Stereo Balanced Inject
- 8 Input Left/Right Assignable
- Current consumption: 90mA
- Discrete signal path
- Over Current Resettable Fused (prevents any module problem from affecting your rack
- ROHS – Lead Free
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="http://www.purpleaudio.com/products/moiyn/" target="_blank">http://www.purpleaudio.com/products/moiyn/</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.MIXING, HASHTAGS.RECORDING, HASHTAGS.LIVE_SOUND, HASHTAGS.SINGLE_SLOT, HASHTAGS.API_500],
    manufacturer: BRAND.PURPLE_AUDIO,
  }
}