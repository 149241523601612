import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const elx7Fatso: IProductData = {
  id: "empirical-elx7-fatso",
  name: "Empirical Labs ELX-7 FATSO",
  description: "FULL ANALOG TAPE SIMULATOR AND OPTIMIZER: FATSO",
  images: [
    "/img/empirical_fatsoel7x_front.png", "/img/empirical_fatsoel7x_right.png", "/img/empirical_fatsoel7x_back.png", 
  ],
  details: [{
    label: "Description",
    markdown: `
How many times have we heard ourselves, other engineers and even musicians say “I like the sound of tape, it’s sooooo musical”?  
We hear it in recording schools, we read about it on forums, we think about it in our spare time; 
but seriously, who among us who isn’t on the wrong side of 40 has really experienced tape? …or enjoys the inconvenience of tape machine alignment?  

Enter the FATSO “EL7” (Full Analog Tape Simulator & Optimizer), which can help eliminate the pining for the forgotten days of great big clunky analog tape machines (and ALL of their associated headaches!!).  
No longer did the production team have to decide between Scotch 250 or Ampex 456; no longer does the mix engineer have to decide between BASF 911 or Zonal 999 for final mixdown.  
No longer would engineers argue the merits of an Ampex ATR-102 vs a Studer A-80-RC because the FATSO can give you the positive attributes of ALL of these things, in an adjustable and repeatable manner, without having to stress over sticking with an initial decision!!  

The FATSO can give you the same “harmonic generation” and “soft clipping” you hear when using tape or “classic” tube-based devices.  
The FATSO can give you the “high-frequency saturation” you could find by driving tape a bit harder than the design engineers had hoped you would (especially true of Ampex 456!!).  
The FATSO can give you the sweet high end and intimate harmonic overtones of transformers; the analog anomalies that are associated with the “classic” equipment of the 60’s, 70’s and 80’s, all without constant calibration or the “stop and wait” rewind time “vibe breaker”.  

The FATSO has 4 meticulously tweaked compression modes.  
There is the “BUSS” setting that gives gentle transient saturation that is synonymous with tape, and settings reminiscent of the SSL® “Quad Compressor”.  
“SPANK” gives you the subtle knee followed by the hard limiting many of us grew to love on SSL® “E Series” consoles.  
The new “ELEVEN” setting gives you the wonderfully unique texture of a vintage 1176 LN run at 20:1 set to its slowest attack (which is still quite fast!) and rather quick release that nets the full force and unique impact of the transients of a sound (think snare drum) with radically limited dynamic range throughout the rest of the signal.  
Then there is the TRACK-ing compressor which is closest to the classic Empirical Labs EL8 “Distressor” sound, and even easier to use.  

Additionally, there are seven (7) different “warmth” settings that help emulate different tape formulations and machine anomalies, along with the greatest button ever installed on any unit ever built: the “TRANNY” button which gives you the final “over the top” harmonic content, compelling low-frequency focus, while adding breath and urgency to any sound it meets.    
With the choice of XLR or ¼” inputs along with the ability to link several units together for 5.1 operation, the FATSO can cure any of the lameness found in the most common analog to digital converter sets, while giving your music the width and breadth that makes folks wax nostalgic for $300 reels of tape and 500+ pound tape machines.  

You have already heard the FATSO thousands of times on major and minor recordings over the years! If you’re serious about your recording work.  
you owe it to yourself to arrange a demo from your favorite reseller in order to discover the magic you can uncover with this unique instrument (that doubles as a piece of your recording kit).  

`
  }, {
    label: "Specifications",
    markdown: `
- Frequency Response: 2 Hz to 60 kHz in clean audio mode (+0, -3 dB)
- Dynamic Range: 110 dB from maximum output (20 %THD soft clipping) to minimum output. Greater than 100 dB signal to noise.
- Distortion: Ranges between .06% and 20% depending on mode and settings.
- I/O: DC Coupled input and outputs.
- Compressor Time Constants: Attack range 1ms – 60ms. Release range .05sec – 3.5 seconds. Attack and Releases are fixed by the compression type selected.
- Power Consumption: 15 Watts Max.
- Metal Chassis: Single height 1.75″ high, 10″ deep, 19″ wide.
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://www.empiricallabs.com/fatso/" target="_blank">https://www.empiricallabs.com/fatso/</a>
    `
    }],
  metadata: {
    hashtags: [HASHTAGS.SATURATOR, HASHTAGS.RACK, HASHTAGS.ONE_U, HASHTAGS.RECORDING, HASHTAGS.MIXING, HASHTAGS.LIVE_SOUND],
    manufacturer: BRAND.EMPIRICAL_LABS,
  },
  videos: [
    "https://www.youtube.com/embed/eD6bRk6z8cc",
    "https://www.youtube.com/embed/tAcQi8TiXd0"
  ]
}