import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const germ500MKII: IProductData = {
  id: "germ-500-mkii",
  name: "Chandler Limited Germ 500 MKII",
  description: "Pre Amp",
  images: ["/img/chandler_limited_germ_500_MKII.png"],
  details: [{
    label: "Description",
    markdown: `
CHANDLER LIMITED furiously enters the 500 race with its new line of 500 Series Modules for your favorite lunch box! Using all discrete circuits that combine Chandler's best designs with the hottest designs from the past, transformer balanced everything, and the American handmade quality you expect from Chandler, the 500 Series Modules will take your lunchbox or rack system to the next level!  
The Germ 500 MKII is an updated version of our original Germ 500. This unit has an improved faceplate layout and nicer look. It uses classic germanium transistors in all Class A, transformer balanced circuits. The Germ 500 MKII is our well-known Germanium Preamp adapted for 500 series racks.  
We use the same transformers and amp blocks to assure the sound is consistent with our rack version.  
We've updated the power on the MKII so there no known power-up issues with current lunchboxes and rack systems.  
All Chandler 500 series units have now been tested and proven to have no power issues in the following racks:  
API 500V 10-slot with L200 PSU; 
API 500-6B Lunchbox; 
API 500-6B HC “new version” Lunchbox; 
BAE 11-slot with power one supply; 
BAE 6-slot portable with power one supply; 
BAE 6-slot with Avedis designed supply; 
A-Designs 2-slot; Purple Sweet 10; 
and Empirical Labs 2-slot.
`
  }, {
    label: "Specifications",
    markdown: `
- FORMAT: 500 Series
- CHANNELS: Mono
- CIRCUIT: Class A, Discrete, Transistor
- GAIN: -5db to +65db
- CONNECTIONS
- I/O: 500 Series Enclosure
- Mic Input: Transformer Balanced, Input Impedance: 200Ω
- DI: 1/4" Unbalanced, Input Impedance: 100k Ω
- PHANTOM POWER: +48v
  `
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://chandlerlimited.com/germ-500-mkii-pre-amp/" target="_blank">https://chandlerlimited.com/germ-500-mkii-pre-amp/</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.MICPRE, HASHTAGS.SINGLE_SLOT, HASHTAGS.API_500],
    manufacturer: BRAND.CHANDLER_LIMITED,
  }
}