
import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const vivid: IProductData = {
  id: "mother-vivid",
  name: "Mother Vivid",
  description: "A modern take on a British classic 3 band EQ with subtle character.",
  specialOrder: true,
  images: ["/img/mother_vivid_front_center.jpg", "/img/mother_vivid_curve.jpg"],
  details: [{
    label: "Description",
    markdown: `
VIVID, 3 band inductor based eq for 500 series, represents our attempt to reformulate
the well-known classic British design, increase its spatial and sonic qualities and make it
more usable for demanding tasks such as mastering applications or processing groups of
acoustic instruments.<br>
Even though it takes inspiration from that well-known eq, it's a whole new creation and so far it's been their best seller due to its subtle character.<br>
<br>
In fact, any source that contains a lot of spatial details which should
be preserved and not damaged, can benefit from the quality that Vivid offers.
The module was designed and manufactured in Serbia, by a small group of dedicated
people, including hand-wound transformers, which we are particularly proud of.<br>
<br>
The eq module contains a specially designed input transformer, wound on an unusually large
core (similar to one that can be find in the bv08 , legendary U47 transformer).<br>
<br>
The result is increased headroom and a very relaxed, lush and focused sound.<br>
<br>
The entire circuit is discrete class A, and contains two custom inductors, which are also manually produced in-house. 
After countless tests, we decided to use tantalum capacitors for all couplings and in other critical places (9 of them actually), similar to what can be
seen on old Siemens devices, and this really made an audible contribution to the overall sound impression.<br>
<br>
**How does it sound?**<br>
The sound is big, open, never aggressive.Vivid is extremely musical, and it is very easy
to find a setting that suits any material.<br>
<br>
**What can it be used on?**<br>
From vocals, acoustic instruments, equalization of ambient microphones, to stereo
material processing.You can use it on “djent” low tuned guitars or a live recorded choral
music,doesn't matter , it will always sound great and be a useful tool.
`
  }, {
    label: "Features",
    markdown: `
- Circuit: Class A , discrete
- IN: Transformer balanced
- OUT: Balanced Line Driver
- MID band: 300 ,720 ,1k5 ,2k6 ,4k ,6k Bell +-10dB Boost-Cut
- HIGH band: Switchable 8kHz Shelving , 20kHz Bell +-17dB Boost-Cut
- LOW  band: Switchable 150Hz Shelving , 60Hz Bell +-17dB Boost-Cut
- HPF: -3 dB on 70Hz
- Bypass: True Bypass
`
  }, {
    label: "Technical Specs",
    markdown: `
- Maximum input level: +21 dBu
- Maximum output level: +21 dBu
- Input impedance: 10k
- Output Impedance: 50
- Noise: <-85 dBA
- THD: 0.0058% at +10dBu
- Max. current draw: 100mA`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://www.mother-proaudio.com/vivid" target="_blank">https://www.mother-proaudio.com/vivid</a>
    `
  }],
  videos: ["https://www.youtube.com/embed/qBt1QjEkubQ?si=-2y17lRcIEid6PF9&amp;start=642", "https://www.youtube.com/embed/pW9k9KWZ8b4?si=pp-LdSy5ojou8vR_"],
  metadata: {
    hashtags: [HASHTAGS.EQ, HASHTAGS.MIXING, HASHTAGS.RECORDING, HASHTAGS.SINGLE_SLOT, HASHTAGS.LIVE_SOUND, HASHTAGS.API_500, HASHTAGS.MASTERING],
    manufacturer: BRAND.MOTHER,
  }
}