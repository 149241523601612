
import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const pants: IProductData = {
  id: "purple-audio-pants",
  name: "Purple Audio 5M2 Pants",
  description: "A four opamp, differential mic preamp.",
  specialOrder: true,
  images: ["/img/purpleaudio_pants_front.png", "/img/purpleaudio_pants_left.png"],
  details: [{
    label: "Description",
    markdown: `
The Pants uses a unique dual-bobbin input transformer from Cinemag unlike any other.  
The smooth Gain control helps you dial in the sound you want.  
If the output level is too high, you can ride the level down with the separate Level control.  
Pressing the “High” switch changes the feedback network to provide an insane 78dB of clean gain, a great match for very low output mics.  

With different Gain, High mode switch, and Level settings the Pants can produce many different tones with the same amount of output level.  
The front panel DI goes through a FET buffer into the input transformer, adding an extra dimension to the sound of the preamp.  

The DI load switch gives you two options, making it even easier to get the best direct tone for your instrument.  
Two LEDs clearly indicate signal level for the gain and output stages at a glance, turning green to indicate signal present, yellow for 10dB below clipping, and red for clipping.  
The Pants uses two KDJ3 opamps and two KDJ4  
`
  }, {
    label: "Features",
    markdown: `
- Output level control
- Input and output tricolor LED metering
- Mic / Line switch for rack input Bridging line input for high output mics or line level sources Use with our Moiyn 8×2 mixer module in the Sweet Ten rack for mic or line mixing
- DI Load switch for front panel input 100kohms or 1M ohms for a more open tone
- Discrete signal path
- Over Current Resettable Fused (prevents any module problem from affecting your rack)
- ROHS – Lead Free
`
  }, {
    label: "Specifications",
    markdown: `
- Maximum output level +33dBu
- Frequency response 10hz – 60kHz +/- 1db

Transformer input:

Mic mode 
- Maximum gain 72dB in normal mode
- Maximum gain 78dB in high mode

Line mode
- Maximum gain 40dB in normal mode
- Maximum gain 42dB in high mode

Direct input:

Mic mode
- Maximum gain 60dB in normal mode
- Maximum gain 64dB in high mode

Line mode
- Maximum gain 25dB in normal mode
- Maximum gain 30dB in high mode
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="http://www.purpleaudio.com/products/pants/" target="_blank">http://www.purpleaudio.com/products/pants/</a>
- <a to="http://www.purpleaudio.com/product/PAPants.PDF" target="_blank">Recall sheet</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.MICPRE, HASHTAGS.MIXING, HASHTAGS.RECORDING, HASHTAGS.LIVE_SOUND, HASHTAGS.SINGLE_SLOT, HASHTAGS.API_500],
    manufacturer: BRAND.PURPLE_AUDIO,
  }
}