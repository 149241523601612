import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const schwerkraftmaschine: IProductData = {
  id: "tegeler-schwerkraftmaschine",
  name: "Tegeler Audio Manufaktur Schwerkraftmaschine (SKM)",
  description: "Remote Controllable Vari-Mu Mastering and Bus Compressor",
  images: ["/img/tegeler-schwerkraftmaschine-front.jpg", "/img/tegeler-schwerkraftmaschine-side.jpg", "/img/tegeler-schwerkraftmaschine-back.jpg"],
  details: [{
    label: "Description",
    markdown: `
The master of mastering
We have spent so much time planning, constructing, testing, programming, checking... and above all else, listening.
This product incorporates the sum of all our expertise, combined with the feedback we got on the VTC.

The result is a Vari-Mu compressor without equal. “The Compressor.”

We are proud of the result and are delighted to be able to put a tool in your hand that will make your music sound even better.

How the Schwerkraftmachine polishes sound to perfection
In the Schwerkraftmaschine, the signal passes through a total of three transformers and four triode tubes per channel.

Why does this matter? Tubes, and especially triodes, add harmonic overtones to the signal that the human ear finds pleasing, while improving our perception of individual sounds.
The individual instruments are given additional presence through the tubes.
Through the conversion of electricity into magnetic fields and back to electricity, the transformers and their non-linearities increase the density of the sound before it is compressed.
The bass becomes cleaner and more defined. The high frequencies are pleasantly rounded.

The Schwerkraftmaschine makes the difference between “well mixed” and “sounds perfect”.
`}, {
    label: "Compression modes",
    markdown: `
Whether it is about softly compressing the sound, a more harder effect, or responding to complex situations...
There is a program for everything.

Generating a digital control signal offers flexibility that simply cannot be found in analogue equipment.
This makes it possible to emulate the behaviour of any existing compressor.

When you need the Schwerkraftmaschine to work like an Optical Compressor, including the memory effect?
But with the Attack and Release still being adjustable?
It’s a piece of cake!

Or you need it soft like a Vari-Mu? Or preferably just like a VCA?

Tasks like this are child’s play for the Schwerkraftmaschine.
We have created completely unique programs that have previously never existed in any compressor, which generates the control signal based on complex rules.

We have created and optimised this equipment through countless listening sessions.
Just choose the right program for the each song.`
}, {
  label: "Features",
  markdown: `
- Stereo device
- Transformer balanced inputs and output
- Frequency response: 20 – 40,000 Hz +/- 1.5dB
- Vari-Mu Compressor
- Digital side-chain
- 11 Compression modes
- Side-chain filter as Low-cut from 0-300 Hz or tilt filter
- Controllable by the webpage of the device or by plug-in (VST, AU, AAX)
- Servo Pots
- Dynamic: >110dB unweighted
- Attenuation-to-crosstalk ratio L/R >90 dB @ 1 kHz
- Internal Power Supply (25W)
- Height: 132mm (3U), Width: 483mm, Depth: 250mm
`
  }, {
    label: "Specifications",
    markdown: `
- Frequency Response: 20Hz - 24kHz
- Maximum Input Level: +20 dBu
- Input Impedance: ≥ 2.4kΩ
- Output Impedance: < 600 Ω
- Maximum Output Level: +24 dBu
- Dynamic Range: ≥ 101 dB
- Voltage: 110- 240 V~/50-60 Hz
- Fuse: 2A slow-blow
- Maximum Power Consumption: < 25 W
- Dimensions: 3 U, H:132 mm, W:483 mm, D:250 mm
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://www.tegeler.com/Schwerkraftmaschine/Index" target="_blank">https://www.tegeler.com/Schwerkraftmaschine/Index</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.COMPRESSOR, HASHTAGS.RACK, HASHTAGS.THREE_U, HASHTAGS.MASTERING, HASHTAGS.LIVE_SOUND],
    manufacturer: BRAND.TEGELER_AUDIO,
  },
  videos: []
}