import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const titan: IProductData = {
  id: "wesaudio-titan",
  name: "WesAudio _Titan",
  description: "NG500 RECALL CHASSIS",
  images: ["/img/wesaudio_titan_front.png", "/img/wesaudio_titan_back.png"],
  details: [{
    label: "Description",
    markdown: `
NG500 recall chassis  

_TITAN is complete recall system designed to provide total integration with your beloved analogue equipment.  
It extends 500 series connector with additional pins where modules can be designed to support digital recall or any other vendor specific functionalities.  
This extension is part of new open standard “ng500” – “Next Generation 500 series” which allows other manufacturers to design and implement digitally controlled analog devices.  
  
We are proud to present _TITAN – Future of analog processors.  
`
  }, {
    label: "Key Features",
    markdown: `
### _TITAN

- 10 SLOTS FOR 500-SERIES or ng500-SERIES MODULES
- HIGH END LINEAR POWER SUPLY capable to deliver 5A of power
- Stereo links are integrated with modules
- CONTROL/RECALL ALL MODULES THROUGH ONE CONNECTION
- Internal stereo link switches
- OPEN SPECIFICATION
- Remote firmware upgrade
- REMOTELY CONTROLED STEREO LINKS
- IAC – INTERNAL AUDIO CONNECTOR
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://wesaudio.com/product/_titan/" target="_blank">https://wesaudio.com/product/_titan/</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.CASES, HASHTAGS.NEXT_GEN, HASHTAGS.STARTING_OUT, HASHTAGS.RACK, HASHTAGS.THREE_U, HASHTAGS.LIVE_SOUND, HASHTAGS.API_500],
    manufacturer: BRAND.WESAUDIO,
  },
  videos: [
    "https://www.youtube.com/embed/snkOoDUtvVk",
    "https://www.youtube.com/embed/Xh1aocC62NI"
  ]
}