import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const radialWorkhorse: IProductData = {
  id: "radial-workhorse",
  name: "Radial Workhorse",
  description: "8 Slot 500 series case WITHOUT summing mixer. You don't need a summing mixer if you do your mixes mostly on a desk and do not use analog summing techniques for your mixes",
  images: ["/img/radial_engineering_workhorse_front.jpg", "/img/radial_engineering_workhorse_back.jpg"],
  details: [{
    label: "Key Features",
    markdown: `
- 500 series 8 slot rack without 8×2 summing mixer
- Plenty of current for power hungry modules
- Dual output headphone buss for monitoring
- DB25, TRS & XLR I/O for flexible patching
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://www.radialeng.com/product/workhorse" target="_blank">https://www.radialeng.com/product/workhorse</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.CASES, HASHTAGS.STARTING_OUT, HASHTAGS.API_500],
    manufacturer: BRAND.RADIAL,
  }
}