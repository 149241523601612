import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const distressorel8Pair: IProductData = {
  id: "empirical-distressorel8s-pair",
  name: "Empirical Labs Distressor EL8s Stereo Pair",
  description: "The Distressor EL8-S (with the S at the end) is simply a pair of tested and matched units for use as Stereo compressors. They can, of course still be used as two separate mono units.",
  images: ["/img/empirical_distressorel8_pair_front.png", "/img/empirical_distressorel8_pair_right.png", "/img/empirical_distressorel8_pair_back.png"],
  details: [{
    label: "Description",
    markdown: `
[See also the single rack version.](/products/empirical-distressorel8)  

For many Distressor owners, one is never enough – in fact, one extremely successful engineer has twelve of them!   

For those who want (at least) a stereo pair, Empirical Labs offers the EL8-S, a pair of Distressors that are matched at the factory, and come with the cables you need to lock them together.   
If you want the Brit Mod and Image Link options, an [EL8X-S](/products/empirical-distressorel8x-pair) package is also available.  
`
  }, {
    label: "Specifications",
    markdown: `
- Frequency Response: 2 Hz to 160 kHz in clean audio mode (+0, -3 dB). Response is shaped in distortion modes (Dist 2, Dist 3).
- Dynamic Range: 110 dB from max. output to min. output in 1:1 mode. Greater than 100 dB signal to noise in distort 3 mode.
- Distortion: Ranges between .02% and 20% depending on distortion mode and release times set on front panel.
- I/O: DC Coupled input and outputs.
- Time Constants: Attack range 50uS – 50mS. Release range .05 sec to 3.5 seconds, normal modes and up to 20 seconds in 10:1 opto mode. Time constants are dependent on ratio.
- Power Consumption: 15 Watts Max.
- Metal Chassis: Single unit: 1.75″ high, 10″ deep, 19″ wide.
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://www.empiricallabs.com/distressor/" target="_blank">https://www.empiricallabs.com/distressor/</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.COMPRESSOR, HASHTAGS.RACK, HASHTAGS.TWO_U, HASHTAGS.RECORDING, HASHTAGS.MIXING, HASHTAGS.LIVE_SOUND],
    manufacturer: BRAND.EMPIRICAL_LABS,
  },
  videos: ["https://www.youtube.com/embed/W8x9UYMfdA4", "https://www.youtube.com/embed/_tResQVjWKM"]
}