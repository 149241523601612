import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const creme: IProductData = {
  id: "tegeler-creme",
  name: "Tegeler Audio Manufaktur Creme",
  description: "Bus Compressor and Mastering Equalizer",
  images: ["/img/tegeler-creme-front.jpg", "/img/tegeler-creme-side.jpg", "/img/tegeler-creme-back.jpg"],
  details: [{
    label: "Description",
    markdown: `
The Creme combines a passive EQ based on the Pultec principle and a high end bus compressor in a single device.

Many successful mixing engineers share one thing in common: They mix with a compressor and EQ in the master channel. The EQ is usually set to subtly boost the bass and treble, while the bus compressor compresses the sound by a couple of dB. This gives the engineer an idea of how the mix will sound after mastering, even still during in the mixing stage. It also helps in finding the right volume balance for individual sounds (especially vocals).

The Creme puts the very best of both functions into one device. The passive EQ has been reduced down to the most popular applications: Bass boost, to give greater definition to the bass, and treble boost, to give the mix the necessary shine. The boost has been made switchable to enable total recall and to reduce the boost to more appropriate levels for use on the master channel.

The bus compressor has a few additional functions such as a low-pass filter for the side chain and an additional ratio of 1.5:1 for especially subtle compression.

The placement of the EQ and compressor in the mix can also be switched.

Similarly, the Creme is suitable for use in mastering as well.
`
  }, {
    label: "Equilizer Features",
    markdown: `
- Passive EQ (Pultec style)
- Bass boost frequencies: 20-30-60-100+140+200 Hz
- Bass boost level: 0-5 dB switchable
- High boost frequencies: 10-12-16-18-20-24 kHz
- High boost level: 0-5 dB switchable
`}, {
    label: "Compressor Features",
    markdown: `
- Lowcut filter: 60-120 Hz
- Attack: 0,1-0,3-1-3-10-30 ms
- Release: 100-300-600 ms-1,2s-Auto
- Ratio: 1,5-2-4-10
- Stepped Potentiometers
- Freqency responce: 20 - 40.000 Hz +/- 1,5 dB
- Balanced inputs and outputs
- Internal power supply with toroidal transformer
- Height: 88,4 mm (2U), width: 483 mm, depth: 250 mm
`
  }, {
    label: "Specifications",
    markdown: `
- Frequency Response: 20Hz - 40kHz
- Maximum Input Level: +22 dBu
- Input Impedance: ≥ 10kΩ
- Output Impedance: < 600 Ω
- Maximum Output Level: +24 dBu
- Dynamic Range: ≥ 110 dB
- Voltage: 230 V~/50Hz or 115V~/60Hz
- Fuse: 500mA slow-blow
- Maximum Power Consumption: < 25 W (0,01A at 230 V)
- Dimensions: 2 U, H:88.1 mm, W:483 mm, D:250 mm
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://www.tegeler.com/Creme/Index" target="_blank">https://www.tegeler.com/Creme/Index</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.COMPRESSOR, HASHTAGS.EQ, HASHTAGS.RACK, HASHTAGS.TWO_U, HASHTAGS.RECORDING, HASHTAGS.MASTERING, HASHTAGS.MIXING, HASHTAGS.LIVE_SOUND],
    manufacturer: BRAND.TEGELER_AUDIO,
  },
  videos: []
}