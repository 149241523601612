import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const varitubecompressor: IProductData = {
  id: "tegeler-varitubecompressor",
  name: "Tegeler Audio Manufaktur Vari Tube Compressor (VTC)",
  description: "Vari-Mu Mastering and Bus Compressor",
  images: ["/img/tegeler-vtc-front.jpg", "/img/tegeler-vtc-side.jpg", "/img/tegeler-vtc-back.jpg"],
  details: [{
    label: "Description",
    markdown: `
## What can the VTC do with your music?
It compresses your mix and glues it together.
It clears up the bass and low midrange and gives it definition.
It adds a golden finish to your sound.

##Why it goes so easy
The VTC is very easy to use:
Simply turn up the input so that the music is compressed sufficiently.
Get the right timing with Attack and Release.
Adjust output level.
Finished!

The liveliness and micro dynamic is preserved even at high compressions. The VTC matches your music. With his 'gilded' tube sound and extremely beautiful, soft compression he is marvelous in merging the mixes into a homogenous whole.

The VTC suits either the mix-bus, solo instruments and sub-groups.

For the accurate reproduction of all settings, all the controllers have been equipped with 42 steps.
With the link switch, you can uncouple both channels and use the separately for separate signals.

## Why the sounds gell together so well
The tube compressor is built after the Vari-Mu principle, like it was used in the Fairchild 670 compressor, has a particularly distinct sound which sounds very musical and already adds the finishing touch to a lot of mixes.

Many hours of fitting, hearing and testing were spent on the VTC.
The VTC starts the compression very gently and only intervenes more strongly at higher compression. A high-pass filter in the analysis circuit reduces the influence of low frequencies on the compression. Together with the appropriate attack and release settings, these measures ensure that the VTC is able to compress the sum without audible pumping, even with a compression of up to 6 dB.

## Why it gilds the sound
The signal passes through a total of 3 transformers and 4 triode tubes per channel in the VTC.

Why is that important? Tubes (especially triodes) add overtones to the signal that the human ear perceives as pleasant and improves the perception of the individual sound sources. The individual instruments receive an additional presence through the tube. Transformers convert the current to a magnetic field and back as current and their non-linearities to provide a pleasant compression of the sound before it is compressed. The bass becomes tidier and more defined. The treble is pleasantly rounded off.

The VTC can make the difference between "mixed quite well" and "sounds perfect".
`}, {
  label: "Features",
  markdown: `
- Stereo or dual mono
- Class-A circuit
- All tube signal path
- Stepped potentiometers
- Transformer balanced XLR inputs and outputs
- Frequency response: 20 – 40.000 Hz +/- 1,5 dB
  `
  }, {
    label: "Specifications",
    markdown: `
- Frequency Response: 20Hz - 24kHz
- Maximum Input Level: +20 dBu
- Input Impedance: ≥ 2.4kΩ
- Output Impedance: < 600 Ω
- Maximum Output Level: +24 dBu
- Dynamic Range: ≥ 95 dB
- Voltage: 230 V~/50Hz or 115V~/60Hz
- Fuse: 500mA slow-blow
- Maximum Power Consumption: < 25 W
- Dimensions: 3 U, H:132 mm, W:483 mm, D:250 mm
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://www.tegeler.com/VariTubeCompressor/Index" target="_blank">https://www.tegeler.com/VariTubeCompressor/Index</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.COMPRESSOR, HASHTAGS.RACK, HASHTAGS.THREE_U, HASHTAGS.MASTERING, HASHTAGS.LIVE_SOUND, HASHTAGS.MIXING],
    manufacturer: BRAND.TEGELER_AUDIO,
  },
  videos: []
}