import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const ngleveler: IProductData = {
  id: "wesaudio-ngleveler",
  name: "WesAudio _NGLeveler",
  description: "NEXT GENERATION AUTOMATION SYSTEM",
  images: ["/img/wesaudio_ngleveler.png"],
  details: [{
    label: "Description",
    markdown: `
16-channel next generation automation system  
ngLEVELER – “Next generation Leveler” is analog automation system with convenient features for better studio integration.  

Besides its automation engine it also brings analog saturation per each channel which helps tracks to get more presence in the mix.  

ngLEVELER doesn’t have any controls, it is either managable via:  
- DAW Plug-in
- HUI controller
- Or both

This approach gives us very convenient fader control with true total recall system via DAW plug-in.  

ngLEVELER was created to become studio centerpiece, and because of its simple analog connectivity it can be used in many applications!  
`
  }, {
    label: "Key Features",
    markdown: `
### ngLEVELER – “Next generation Leveler”
- 16 channels of analog automation with total recall
- +25dBu(IN) / +28dBu(OUT) of headroom
- 6dB passive attenuator (PAD)
- Very low noise and distortion – THD+N < 0.01%
- MUTE and SOLO
- HUI controller integration
- 16 channels of Proprietary THD
- IN and OUT (before and after signal leveling) metering
- Flexible DAW/Live plug-in
- DB-25 analog connectivity
- Ethernet or USB
`
  }, {
    label: "Specifications",
    markdown: `
- THD+N(20Hz-20kHz)	at 0dBu < 0.008%
- Freq response	0-250kHz (-3dB)
- Input impedance	> 10kohm
- Output impedance	< 50ohm
- Crosstalk	> 100dB
- Max input level	+25dBu
- Max output level	+28dBu
- Dynamic range	> 118dB
- Hardware Digital resolution	~2500 steps per channel*
*DAW, or HUI controller may support lower resolution, in that case it will translate to lower number of HW steps.  
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://wesaudio.com/product/ngleveler/" target="_blank">https://wesaudio.com/product/ngleveler/</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.NEXT_GEN, HASHTAGS.RACK, HASHTAGS.MIXING, HASHTAGS.MASTERING, HASHTAGS.LIVE_SOUND, HASHTAGS.ONE_U],
    manufacturer: BRAND.WESAUDIO,
  },
  videos: [
    "https://www.youtube.com/embed/kuQm1LORjaY",
    "https://www.youtube.com/embed/jhmYXjgJObk",
  ]
}