import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const mythvca500: IProductData = {
  id: "tegeler-mythvca500",
  name: "Tegeler Audio Manufaktur MythVCA 500",
  description: "500er VCA Compressor",
  images: ["/img/tegeler_mythvca500.png"],
  details: [{
    label: "Description",
    markdown: `
When developing the MythVCA 500, we asked ourselves how we could make a VCA compressor that would be powerful, versatile, complete, and, as a 500-series device, as compact as possible. 
We wanted a device that could help bring out any tracks to the forefront of a mix, but that could also be used on any mix bus to glue it together.   
Of course, we also wanted it to be usable for tracking purposes: how many times were you in a situation where you needed that voice to be recorded with just a little bit more magic on it?

This is the perfect tool to even out any signal you throw at it: the MythVCA has three different ratio settings (2:1, 4:1, and 10:1), attack times between 0.1ms and 20ms, and release times from 0.1ms to 1.4s. That means you will be able to compress your tracks as softly or as strongly as you want, without ever destroying or cutting your transients too much. Just like its big brother, the Creme, it also has an auto-release function, which will “hear” your signal and release the compression as musically as possible.
Oh, but there is more: the MythVCA can also be linked with another MythVCA through an included linking bridge to process stereo signals. Main Bus, here it comes!

`}, 
// {
//   label: "Features",
//   markdown: `
// - API-500 series mono equalizer
// - Toggle switch: in/bypass
// - High band shelf filter: 3kHz, 3.3kHz, 3.7kHz, 5.5kHz, 8.5kHz, 10kHz
// - Upper midrange bell filter: 1kHz, 1.5kHz, 2.5kHz, 4.5kHz, 12kHz, 16kHz
// - Lower midrange bell filter: 80Hz, 120Hz, 190Hz, 330Hz, 900Hz, 1.3kHz
// - Low band shelf filter: 50Hz, 55Hz, 65Hz, 100Hz, 300Hz, 450Hz
// - Maximum boost/cut: +-15dB
// `
// }, {
//     label: "Specifications",
//     markdown: `
// - Frequency response: 20Hz-40kHz
// - Maximum input level: +20dBu
// - Input impedance: ≥2.4kΩ
// - Output impedance: <600Ω
// - Maximum output level: +21dBu
// - Dynamic range: ≥100dB
// - Supply voltage: +-16V
// - Fuse: -
// - Maximum current consumption: 80mA
// - Size: standard API 500 module size
// - Weight: 540g
//     `
// }, 
{
    label: "Manufacturer Links",
    markdown: `
- <a to="https://www.tegeler.com/MythEQ500/Index" target="_blank">https://www.tegeler.com/MythEQ500/Index</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.COMPRESSOR, HASHTAGS.SINGLE_SLOT, HASHTAGS.API_500, HASHTAGS.LIVE_SOUND, HASHTAGS.MIXING, HASHTAGS.MASTERING],
    manufacturer: BRAND.TEGELER_AUDIO,
  },
  videos: []
}