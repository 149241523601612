import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const distressorel8xPair: IProductData = {
  id: "empirical-distressorel8x-pair",
  name: "Empirical Labs Distressor EL8xs Stereo Pair",
  description: "The Distressor EL8-XS (with the S at the end) is simply a pair of tested and matched units for use as Stereo compressors. They can, of course still be used as two separate mono units.",
  images: ["/img/empirical_distressorel8x_pair_front.png", "/img/empirical_distressorel8x_pair_left.png", "/img/empirical_distressorel8x_pair_right.png"],
  details: [{
    label: "Description",
    markdown: `
[See also the single rack version.](/products/empirical-distressorel8x)  

While all versions of the EL8 “Distressor” have the same sound and basic abilities, the EL8X has a couple of added features that bring it to the next level of “Distressing”! 
The two additions to the EL-8 in the EL8X are the “British Mode”, and the “Image Link” modifications. 
During the 1970s recording engineers would often find new and exciting ways to “misuse” various bits of equipment found in recording studios of the time. 
This was especially true of the UREI 1176LN.  

The original 1176LN had 4 buttons with which you could select the compression ratio. There were 4:1, 8:1, 12:1, and 20:1. 
Boredom (and probably some elements of substance abuse) led some engineers of the time to push all 4 buttons in at the same time the unit would produce an exceptionally cool (and until now) unparalleled aggressive texture to the audio. 
What was actually happening was that the 20:1 and 4:1 compression circuits were both engaged at the same time (it was just easier to get all 4 buttons to stay in than the top and bottom button, and frankly it wasn’t until the “recreation clones” started to come out that anyone bothered to examine the science of the “multi-button” trick.  

Some say this practice started in New York studios, others say it started in the UK. As Empirical Labs is based in New Jersey, nothing “New York” is worthy of acknowledgment (smiley face here); 
hence the feature is called “British Mode”. 
Image Link gives the EL8-X an additional capability when using two units in stereo. 
The original EL8 employed a rather unique “summing and phase detection” method to achieve stereo lock, and while this added a “thickening” component, it was not always the best complement when an engineer wanted to maintain an absolute “left/right” balance at all times.  

The Image Link takes a more traditional approach to locking two units in stereo so the “left/right image” will hold true. 
Additionally, you can combine the two settings to gain a 3rd and absolutely unique to the Distressor capability. 
This capability combines the phase-related aspect of the original link function with the additional “Image Link” function for perfect “left / right” balance in addition to the “thickening” factor created by the original circuit.  
`
  }, {
    label: "Specifications",
    markdown: `
- Frequency Response: 2 Hz to 160 kHz in clean audio mode (+0, -3 dB). Response is shaped in distortion modes (Dist 2, Dist 3 ).  
- Dynamic Range: 110 dB from max. output to min. output in 1:1 mode. Greater than 100 dB signal to noise in distort 3 mode.  
- Distortion: Ranges between .02% and 20% depending on distortion mode and release times set on front panel.  
- I/O: DC Coupled input and outputs.  
- Time Constants: Attack range 50uS – 50mS. Release range .05 sec to 3.5 seconds, normal modes and up to 20 seconds in 10:1 opto mode. Time constants are dependent on ratio.  
- Power Consumption: 15 Watts Max.  
- Metal Chassis (Single unit): 1.75″ high, 10″ deep, 19″ wide.  
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://www.empiricallabs.com/distressor/" target="_blank">https://www.empiricallabs.com/distressor/</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.COMPRESSOR, HASHTAGS.RACK, HASHTAGS.TWO_U, HASHTAGS.RECORDING, HASHTAGS.MIXING, HASHTAGS.LIVE_SOUND],
    manufacturer: BRAND.EMPIRICAL_LABS,
  },
  videos: [
    "https://www.youtube.com/embed/k0T3bxkabdU"
  ]
}