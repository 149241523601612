
import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const odd: IProductData = {
  id: "purple-audio-odd",
  name: "Purple Audio 5E1 Odd",
  description: "A four band inductor based EQ",
  specialOrder: true,
  images: ["/img/purpleaudio_odd_front.png", "/img/purpleaudio_odd_left.png"],
  details: [{
    label: "Description",
    markdown: `
Designed to work with standard 500 racks.  
Designed by Ed Anderson for Purple Audio.  

A modern update of the classic “swinging inputs” console EQ, with four easy to use bands.  
Each band uses a high quality inductor and carefully selected caps to give two switchable frequencies.  
The bands are carefully shaped and spaced to be musical, even at full boost or cut.  
The highest band can be switched from bell to shelf, to boost frequencies extending well beyond 20kHz.  
The lowest band can also be switched from bell to shelf, reaching down into the subsonic range.  

There are illuminated pushbuttons for additional fixed low (red) and high (yellow) cut filters.  
A relay true bypass (green) allows for easy comparison with unprocessed source material.  
With the low and high cut switched out, and all four bands set to 0, the frequency response is +/- 0.25dB from 15Hz to 25kHz, and -3dB at 60kHz.  

With specialized input and output transformers, the Odd has plenty of headroom, even at full boost.  
The entire circuit is discrete, with no electrolytic capacitors in the signal path, and no servo amps.  
With only two active stages, the current draw is low enough to work in any rack, and leave plenty of juice for other modules.  
The Odd uses one KDJ3 opamp and one KDJ4 opamp.    
`
  }, {
    label: "Features",
    markdown: `
- High frequency switchable from bell to shelf
- High frequency 12kHz / 8kHz
- High-mid frequency 4kHz / 1.8kHz
- Low-mid frequency 650Hz / 300Hz
- Low frequency 150Hz / 75Hz
- Low frequency switchable from bell to shelf
- Each band boost or cut by 12dB
- Switchable High cut at 12kHz
- Switchable Low cut 60Hz
- Relay True Bypass
- Tricolor LED level meter
- Transformer balanced in and out
- Discrete signal path
- No electrolytic caps in signal path
- Current consumption: 60mA
- Over Current Resettable Fused (prevents any module problem from affecting your rack)
- ROHS – Lead Free
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="http://www.purpleaudio.com/products/odd/" target="_blank">http://www.purpleaudio.com/products/odd/</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.EQ, HASHTAGS.MIXING, HASHTAGS.RECORDING, HASHTAGS.SINGLE_SLOT, HASHTAGS.LIVE_SOUND, HASHTAGS.API_500],
    manufacturer: BRAND.PURPLE_AUDIO,
  }
}