export enum HASHTAGS {
  STARTING_OUT = "starting out",
  CASES = "cases",
  COMPRESSOR = "compressor",
  DESSER = "desser",
  SATURATOR = "saturator",
  DUAL_SLOT = "dual slot",
  SINGLE_SLOT = "single slot",
  EQ = "eq",
  MASTERING = "mastering",
  LIVE_SOUND = "live sound",
  RECORDING = "recording",
  MIXING = "mixing",
  NEXT_GEN = "next generation",
  MICPRE = "mic pre",
  INTERFACES = "interface",
  ACCESSORIES = "accesories",
  RACK = "rack mounted",
  ONE_U = "1u",
  TWO_U = "2u",
  THREE_U = "3u",
  FOUR_U = "4u",
  API_500 = "API 500 Series",
  VST = "vst",
  FX = "effects",
  SUMMING_MIXER = "summer"
}
