export enum PATHS {
  INDEX = "/",
  EMPTY = "",
  PRODUCTS = "products",
  RESOURCES = "resources",
  SEARCH = "search",
  ABOUT = "about",
  FAQ = "faq",
  WILD_CARD = "*",
  BRANDS = "brands",
  CHECKOUT = "checkout",
  QUOTE = "quote"
}