import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const prometheusEclipse: IProductData = {
  id: "wesaudio-prometheus-eclipse",
  name: "WesAudio Limited Edition Eclipse _Prometheus",
  description: "NG500 fully analog passive equalizer with digital recall with a limited edition black plate.",
  images: ["/img/wesaudio_prometheus_eclipse_front.jpg", "/img/wesaudio_prometheus_eclipse_side.jpg", "/img/wesaudio_prometheus_eclipse_angle.jpg", "/img/wesaudio_prometheus_plugin.png"],
  details: [{
    label: "Description",
    markdown: `
Limited edition Eclipse with new black faceplate. Discover modern and new enhanced GUI revolutionizing the experience in sound engineering.

NG500 fully analog passive equalizer with digital recall with new limited Black edition with fully redesigned plugin interface.

_PROMETHEUS ECPLIPSE is fully analog passive EQ with +24dBu of headroom. Featuring a pultec-style warm sound with a lot of innovative improvements its modern approach to design allows instant recall of your classic vibe through very flexible plugin. _PROMETHEUS ECLIPSE as all ng500 products, integrates with your DAW environment via front panel USB socket or _TITAN chassis.

[Check out the standard version](/products/wesaudio-prometheus)
`
  }, {
    label: "Key Features",
    markdown: `
### _PROMETHEUS equalizer with digital recall
- (Limited edition) Black Faceplate
- New Redesigned GUI interface
- +24dBu of headroom
- Stereo / Dual mono / Mid/Side operation mode
- Passive equalizer (Pultec-style) design based on inductors
- Analog inductor
- Proprietary THD implementation
- Flexible DAW/Live plug-in control for instant recall
- 6 touch sensitive encoders allows to record automation
- True bypass
- In and OUT metering with clip detection
- A/B compare feature
`
  }, {
    label: "Specifications",
    markdown: `
- Frequency response	10Hz-150kHz (-2dB)
- THD+N < 0.01%	(1kHz,0dBu)
- Input impedance	10kohm
- Output impedance	< 100ohm
- Max signal level	+24dBu
- Crosstalk	< -100dB
- THD (MID switch)	1%
- THD (HIGH switch)	2.5%
- Power consumption	70mA per rail
- Dimensions	76x133x158mm
- Warranty 2 years
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://wesaudio.com/product/_prometheus/" target="_blank">https://wesaudio.com/product/_prometheus/</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.EQ, HASHTAGS.DUAL_SLOT, HASHTAGS.NEXT_GEN, HASHTAGS.MIXING, HASHTAGS.MASTERING, HASHTAGS.LIVE_SOUND, HASHTAGS.API_500],
    manufacturer: BRAND.WESAUDIO,
  },
  videos: [
    "https://www.youtube.com/embed/H38wYd7DgKw?si=c1Ji1Civ-QPwFZ_A",
    "https://www.youtube.com/embed/5VkpLj73_us?si=7MPGUFuPdz_Y0Yh9",
    "https://www.youtube.com/embed/z4rN4z_7qMA"
  ]
}