import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const rhea: IProductData = {
  id: "wesaudio-rhea",
  name: "WesAudio _Rhea",
  description: "Stereo vari mu compressor with digital recall",
  images: ["/img/wesaudio_rhea_front.png", "/img/wesaudio_rhea_back.png"],
  details: [{
    label: "Description",
    markdown: `
Fully analog, stereo vari-mu tube compressor with digital recall.  

Since digital footprint became part of the modern age, analog flavor was demanded more than ever. 
It is hard to imagine more musical and rich sounding compression than vari-mu desgin. 
This time ng500 line presents _RHEA – most noble of all, where modern age meets vintage, literally.  

Regardless its digital control features, _RHEA is fully analog unit.  
Audio signal is processed only via analog components!  
`
  }, {
    label: "Key Features",
    markdown: `
### _RHEA stereo vari-mu tube compressor  
- 100% analog device with +24dBu of headroom
- True stereo tube “vari-mu” style compressor
- High voltage tube operation
- Interstage CARNHILL transformers
- Input and output level control for maximum flexibility
- Mix knob for parallel compression
- THD – harmonic distortion with two modes (Medium & High)
- SIDE CHAIN FILTERS – 3 high pass filters at 60,90 and 150 Hz
- 500 series and ng500 (e.g. _TITAN) series compatible
- Total Recall and plugin control compatible with most DAWs.
- Analog automation in DAW
`
  }, {
    label: "Specifications",
    markdown: `
- Frequency response	10Hz-150kHz (-3dB)
- THD+N < 0.03% (1kHz,0dBu)	no compression
- THD+N >= 1%	at maximum compression level
- Input impedance	20kohm
- Output impedance	< 100ohm
- Max signal level	+24dBu
- Crosstalk	< -80dB
- THD (MID switch)	1%
- THD (HIGH switch)	3%
- Attack	0.5,1,3,10,30,50 (ms)
- Release	0.1,0.3,0.6,0.9,1.8,3.6 (s)
- SC Filter	60,90,150 Hz
- Power consumption	190mA per rail
- Dimensions	76x133x158mm
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://wesaudio.com/product/_rhea/" target="_blank">https://wesaudio.com/product/_rhea/</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.COMPRESSOR, HASHTAGS.DUAL_SLOT, HASHTAGS.NEXT_GEN, HASHTAGS.MIXING, HASHTAGS.LIVE_SOUND, HASHTAGS.API_500],
    manufacturer: BRAND.WESAUDIO,
  },
  videos: [
    "https://www.youtube.com/embed/2F_QsOvlBqQ",
    "https://www.youtube.com/embed/eD6bRk6z8cc",
    "https://www.youtube.com/embed/e_1-_a-pJDk"
  ]
}