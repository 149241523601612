
import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const biz: IProductData = {
  id: "purple-audio-biz",
  name: "Purple Audio 5M1 Biz",
  description: "A unique sounding, three amplifier, discrete microphone preamp module.",
  specialOrder: true,
  images: ["/img/purpleaudio_biz_front.png", "/img/purpleaudio_biz_left.png"],
  details: [{
    label: "Description",
    markdown: `
The smooth Gain control helps you dial in the sound you want.  
If the output level is too high (the Class A/B output opamp can pump out up to +27 dBu), you can ride the level down with the separate Level control.  

Pressing the “Dual” switch splits the gain between two of our Class A opamps, enabling up to 75 dBu of clean gain, making the Biz a perfect match for ribbon microphones.  

With different Gain, Level, and Dual settings the Biz can produce an infinite variety of tonal variations with the same amount of output level.  
The front panel DI helps you record instruments and line level sources with unrivaled clarity and punch.  

Two LEDs clearly indicate signal level for the gain and output stages at a glance, turning green to indicate signal present, yellow for 10dB below clipping, and red for clipping.  
The Biz uses two KDJ3 opamps and one KDJ4 opamp.  
`
  }, {
    label: "Features",
    markdown: `
- Output level control
- Input and output tricolor LED metering
- Mic / Line switch for rack input Bridging line input for high output mics or line level sources Use with our Moiyn 8×2 mixer module in the Sweet Ten Rack for mic or line mixing
- DI Load switch for front panel input 33kohms as in the original Biz or 100kohms for a more open tone
- Discrete signal path
- Over Current Resettable Fused (prevents any module problem from affecting your rack)
- ROHS – Lead Free
`
  }, {
    label: "Specifications",
    markdown: `
- Maximum output level +27dBu
- Frequency response 10hz – 70kHz +/- 1db (3db down at 100kHz)
- Transformer input:
- Mic mode
- Maximum gain 60dB in single mode
- Maximum gain 75dB in dual mode
- Line mode
- Maximum gain 35dB in single mode
- Maximum gain 50dB in dual mode
- Direct input:
- Maximum gain 30dB in single mode
- Maximum gain 40dB in dual mode
- Beginning Serial Number 1600 February 2008:
- Over Current Resettable Fused (prevents any module problem from affecting your rack)
- ROHS – Lead Free
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="http://www.purpleaudio.com/products/biz/" target="_blank">http://www.purpleaudio.com/products/biz/</a>
- <a to="http://www.purpleaudio.com/product/PABizMk.PDF" target="_blank">Recall sheet</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.MICPRE, HASHTAGS.MIXING, HASHTAGS.RECORDING, HASHTAGS.LIVE_SOUND, HASHTAGS.SINGLE_SLOT, HASHTAGS.API_500],
    manufacturer: BRAND.PURPLE_AUDIO,
  },
  videos: ["https://www.youtube.com/embed/ymyQ7yaKHw0"]
}