import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const ng76: IProductData = {
  id: "wesaudio-ng76",
  name: "WesAudio _NG76",
  description: "Next Gen 1176 FET Compressor",
  images: ["/img/wesaudio_ng76_front.webp", "/img/wesaudio_ng76_back.webp"],
  details: [{
    label: "Description",
    markdown: `
100% analog FET compressor - ng76 is a 100% analog processing unit with 24dBu of headroom.     
Regardless of its full digital control, the analog signal path provides lightning speed compression sound we all know from countless records.    

True [stereo](/products/wesaudio-ng76-stereo-pair) operation: 2 x ng76 mono compressors can be linked via dedicated software.   
It allows linking compression detectors (with special cables included with [stereo](/products/wesaudio-ng76-stereo-pair) matched pairs) for true [stereo](/products/wesaudio-ng76-stereo-pair) compression,   
or dual mono compression with all parameters linked.  

MIX knob for parallel compression - We all know FET style compression to be lightning fast, punchy, and aggressive.   
The mix knob for parallel compression allows us to dial in the amount of this sonic flavor we want!  

THD - total harmonic distortion - Total harmonic distortion button which allows You to dial in additional sonic content in Low or High settings.   
This design is fully based on the FET circuit and allows us to choose how saturated Your compression becomes.   

Carnhill transformers - Transformers in audio compressors aren't just for electrical isolation – they can add a warm, sonic saturation we all love in our recordings.   
Carnhill transformers are part of the ng76 adding all that vintage flavor!  

Saturation mode - Special mode where static compression is triggered to produce sonics based on FET circuit, and One knob which sets INPUT and OUTPUT in an opposite way. It allows the use of the unit as a saturation box / clipper.
The harder the drive will hit the compression and saturation circuit, the more sonic content we will get!  

Selectable Input stage - Modern and Vintage modes to support two 1176 revisions (F & G):  
- Vintage - transformer balanced input,  
- Modern - Electronically balanced input.  

Side Chain EQ sections - Sophisticated side chain circuit EQ with two independent sections:  
a. HPF 60 hz / 90 Hz / 150 Hz - to maintain low end information while compressing,  
b. High Shelf of 2kHz / 5kHz, 10kHz - to tame high frequencies by emphasizing high/hid mids content.  

Hardware A/B buttons to compare two independent settings.
Even using ng76 as a pure analog signal processor you can easily switch between two parallel settings with the magic of a simple button. 

USB or Ethernet Connectivity Gain reduction, input and output metering - ng76 is armed with classic 

Vintage Gain Reduction meter we all recognize and know. To provide a high level of usability, it also visualizes very precise input and output levels at the same time!

Automation - Just draw automation lines in your DAW and your analog device will follow! And remember, you can use five touch sensitive encoders to record automation TO your DAW!
TOTAL Recall and plug-in control.
`
  }, {
    label: "Key Features",
    markdown: `
### ng76 fully analog, digital control FET compressor.
- 100% analog FET compressor with 24dBu of headroom
- Classic lightning speed compression style based on WesAudio Beta76 design
- Full Total Recall and plugin control compatible with most DAWs
- Classic controls known from FET style compressor – INPUT / OUTPUT / ATTACK / RELEASE
- Additional MIX knob for parallel compression
- Carnhill Transformers (input and output)
- Saturation mode – Special mode where static compression is triggered to produce sonics based on FET circuit, and One knob which sets INPUT and OUTPUT in an opposite way. It allows the use the unit as a saturation box / clipper  

- Four ratio settings 4:1 / 8:1 / 12:1 / 20:1 – all possible combinations including ALL button mode!
- Modern and Vintage modes to support two 1176 revisions (F & G):  
    – Vintage – transformer balanced input,  
    – Modern – Electronically balanced input  
- Sophisticated side chain circuit EQ with two independent sections:  
    – HPF 60 hz / 90 Hz / 150 Hz – to maintain low end information while compressing,  
    – High Shelf of 2kHz / 5kHz, 10kHz – to tame high frequencies by emphasizing high/hid mids content  
- Vintage gain reduction meter
- Very precise INPUT and OUTPUT metering
- Hardware A/B buttons to compare two independent settings
- THD – total harmonic distortion with LOW and HIGH settings based on the FET circuit
- Fully digitally controlled and isolated analog circuit
- ANALOG AUTOMATION in DAW
- Ethernet and USB connectivity for integration with digital plug-in
- Size: 2U
`
  }, {
    label: "Specifications",
    markdown: `
- Attack time	20 us – 800 us
- Release time	50 ms – 1100 ms
- Compression ratio	4:1; 8:1; 12:1; 20:1; all button mode and all combinations True Bypass
- SC High Pass filter	60,90,150Hz
- SC High Shelf filter	2KHz, 5KHz, 10kHz
- Output impedance	50 ohm
- Frequency response	20Hz to 20kHz (+/- 1dB)
- Signal to noise ratio SNR	SNR >83dB
- Max signal level	+24dBu
- THD+N MIN	0.2%
- Noise floor	-95dBu
- Power consumption	8W
- Dimensions	88x483x235mm
- Weight	6 kg
- Balanced output and input	
- Case Rack	2U
- Warranty	2 years
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://wesaudio.com/product/ng76/" target="_blank">https://wesaudio.com/product/ng76/</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.COMPRESSOR, HASHTAGS.NEXT_GEN, HASHTAGS.MASTERING, HASHTAGS.MIXING, HASHTAGS.LIVE_SOUND, HASHTAGS.TWO_U, HASHTAGS.RACK],
    manufacturer: BRAND.WESAUDIO,
  },
  videos: [
    "https://www.youtube.com/embed/LGGgYkReGSA",
    "https://www.youtube.com/embed/7M5LRAdAyD0"
  ]
}