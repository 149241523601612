import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const bigFreq: IProductData = {
  id: "empirical-big-freq",
  name: "Empirical Labs BIG FrEQ",
  description: "Inspired by the precision and warmth of Empirical Labs Lil FrEQ parametric equalizer, BIG FrEQ introduces superior fine-tuning controls for surgical tone shaping and a unique FINISHER section for adding organic harmonic distortion.",
  images: ["/img/empirical_big_freq.png"],
  details: [{
    label: "Description",
    markdown: `
With twelve powerful sections of processing, BIG FrEQ offers the ability to create modern “flat-top” parametric shapes with an analog workflow and added controls for more tone-shaping possibilities than a typical EQ plug-in.   
Inspired by the precision and warmth of Empirical Labs Lil FrEQ parametric equalizer, BIG FrEQ introduces superior fine-tuning controls for surgical tone shaping and a unique FINISHER section for adding organic harmonic distortion.   
Like the plug-in’s predecessor, the Arousor, Empirical Labs brings the soul of analog hardware to the digital realm and includes features that can not be replicated in the analog domain.   
As always, Empirical Labs offers new twists and functionality to their new plug-in EQ and labors over making it mindless to learn, and just plain FUN to use.   

FLAT TOP SHAPES  
Create modern tone curves with the added SLOPE control on each parametric band.   
This fourth EQ parameter steepens the edges of the curve creating a plateau shape allowing more frequencies to be adjusted equally.  

ANALOG SATURATION  
Dial in organic, tape-like saturation with the unique FINISHER section and know how much saturation is being applied with the saturation meter.  

FOCUS MODE  
Switching on the FOCUS mode will center the selected frequency and reduce the range of FrEQ knob for easy fine tuning.  

MORE RANGE  
Expand the range of the GAIN knob an extra 15dB by toggling the RANGE mode for more dramatic boosts and cuts.  
 
UNMATCHED PRECISION  
Toggle solo mode on any band to listen to the exact frequencies you are effecting.  

INTUITIVE WORKFLOW  
Copy and paste settings from band to band as well as plugin to plugin for increased speed and more intuitive workflow.  
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://www.empiricallabs.com/product/big-freq/" target="_blank">https://www.empiricallabs.com/product/big-freq/</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.EQ, HASHTAGS.VST, HASHTAGS.RECORDING, HASHTAGS.MIXING, HASHTAGS.MASTERING],
    manufacturer: BRAND.EMPIRICAL_LABS,
  }
}