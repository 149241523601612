import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const radialWorkhorseWMixer: IProductData = {
  id: "radial-workhorse-mixer",
  name: "Radial Workhorse (with summing mixer)",
  description: "500 series case with summing mixer. If you want to take your lunchboxes with you to a live environment, and need some knobs for volume contronl, then you will need a summing mixer with your lunchbox.",
  images: ["/img/radial_engineering_mixer_front.jpg", "/img/radial_engineering_workhorse_front.jpg", "/img/radial_engineering_workhorse_back.jpg"],
  details: [{
    label: "Key Features",
    markdown: `
- 500 series 8 slot rack with 8×2 summing mixer
- Plenty of current for power hungry modules
- Dual output headphone buss for monitoring
- DB25, TRS & XLR I/O for flexible patching
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://www.radialeng.com/product/workhorse" target="_blank">https://www.radialeng.com/product/workhorse</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.CASES, HASHTAGS.API_500],
    manufacturer: BRAND.RADIAL,
  }
}