import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const docDerrH: IProductData = {
  id: "empirical-docderr-h",
  name: "Empirical Labs DocDeer Horizontal",
  description: "Multi-purpose tone enhancement API 500 series module with instrument pre, four-band EQ, compressor, and tape saturation circuits. Horizontal faceplate.",
  images: ["/img/empirical_docdeer_front.jpg", "/img/empirical_docdeer.jpg"],
  details: [{
    label: "Description",
    markdown: `
[See the API 500 series version (vertical).](/products/empirical-docderr)  

With over two years of meticulous development and testing behind it, the DocDerr provides an unprecedented combination of power and musicality in a compact, easy to use format.  
Empirical Labs’ first compressor in the API 500 format.  

The DocDerr’s versatile EQ, low-noise preamp, and unique dynamics section allows for shaping the tone of any track in any situation.  

Whether its on the road, in the studio, or in your guitar rig, the DocDerr has got you covered! The 500 Series format allows for any sticky situation and can clean up any thin guitar sounds or over-bearing vocal chains. 

**Multi-Purpose Tone Module**  

An extremely low-noise preamp capable of working with line level or instrument level signals, is followed by four sections of EQ combining a two position high pass filter with three parametric bands, each with its own set of carefully tuned frequency presets.   

An internal jumper can turn the DocDerr into an unparalleled instrument preamp with 16dB of gain, ideal for acoustic guitar, bass, synths, exotic plucked instruments, etc.  

The versatile EQ is followed by a unique dynamics section which offers renowned Empirical Labs compression at an unprecedented price, and an extremely musical tape emulation circuit that softens and tames high frequencies and transient spikes.   

We believe it’s the best single knob “knee” compression available in this format.  

One of the pivotal features of the DocDerr is the “Mix” knob which allows the user to blend the uncompressed EQ’s signal with the compressed and saturated signal.   

This parallel compression technique, used by top recording and mixing engineers for many years unlocks a whole world of sonic possibilities.  
`
  }, {
    label: "Specifications",
    markdown: `
- Frequency Response: 3 Hz to 180 kHz on eq section (-3dB points)
- Dynamic Range: 120 dB from maximum output (.5% THD soft clipping) to minimum output. Noise floor with Mix on 0 is typically - 91dBu.
- Distortion: Between .0006% and 10% at 1KHz, depending on mode and settings.
- I/O: Maximum Input before hard clipping - +22dBm, "Line In" mode. Maximum Output (Mix at 0) - +28.5dBm.
- Impedance: 120k Ohm, Instrument In Mode, output impedance is less than 75 ohms. 20K input impedance in balanced Line in mode.
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://www.empiricallabs.com/docderr/" target="_blank">https://www.empiricallabs.com/docderr/</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.EQ, HASHTAGS.SINGLE_SLOT, HASHTAGS.RECORDING, HASHTAGS.MIXING, HASHTAGS.MASTERING, HASHTAGS.LIVE_SOUND],
    manufacturer: BRAND.EMPIRICAL_LABS,
  },
  videos: [
    "https://www.youtube.com/embed/ecBteeYg0iA"
  ]
}