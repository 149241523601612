import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const eqp1: IProductData = {
  id: "tegeler-eqp-1",
  name: "Tegeler Audio Manufaktur EQP-1",
  description: "Passive Tube Equalizer",
  images: ["/img/tegeler-eqp1-front.jpg", "/img/tegeler-eqp1-back.jpg"],
  details: [{
    label: "Description",
    markdown: `
Did you ever hear such highs with a software-EQ?
I think not!
With this EQ, you tune in the highs and bass and it sounds just as good as you expected it and even better. You will be astonished and ask yourself how you have ever managed without the Classic Equalizer. Haven't you thought somehow after every Plug-in that you've tried that it could work better? You knew it and now you have found it! Get the Classic Equalizer and experience it for yourself.

Even though there are major advances within the digital technique during the past years, good sounding equalizers are still in the domain of analog devices.
And those who have heard the Classic Equalizer once know why: voices sound silky, instruments step out of the mix and are clearly locatable without one turning them up.
Deep frequency instruments like bass and bass drums get the necessary boost without sounding boomy. If you raise up the treble, it doesn't sound like lifted highs but everything simply sound fresher.

The Classic Equalizer EQP-1 was built after the model of classic vintage equalizers with passive components (condenser and inductor) in the EQ section which accounts for it´s special sound.

The EQP-1 consists of three parts: Bass Boost and Cut, Treble Boost with adjustable bandwidth and a High Frequency Cut.
  
  
## What is the tube stage doing?
The Classic Equailzer is equipped with a tube stage and high quality input and output transformers.

Beside the open and airy sound of the equalizer the signal will get more density and additional presence by the tube and the transformers. The low end becomes more transparent and defined. The highs will sound more rounded and pleasant to the ears.
This effect will already take place as soon as the device is in the signal path and even if the equalizer is not used.
`
  }, {
    label: "Features",
    markdown: `
- Low boost/attenuation (Low shelf):
- 20Hz, 30Hz, 60Hz, 100Hz
- High boost (Peak with variable bandwidth):
- 3kHz, 4kHz, 5kHz, 6kHz, 8kHz, 10kHz, 12kHz, 16kHz, 20kHz
- High attenuation: (High shelf) 5kHz, 10kHz, 20kHz
- Balanced input and output
- Input and output transformer
- Bypass switch
- internal power supply
- Height: 88,1mm (2HE), width:483mm, depth:220mm
`}, {
    label: "Specifications",
    markdown: `
- Frequency Response: 20Hz - 22kHz
- Maximum Input Level: +20 dBu
- Input Impedance: ≥ 2.4kΩ
- Output Impedance: < 600 Ω
- Maximum Output Level: +22 dBu
- Dynamic Range: ≥ 95 dB
- Voltage: 230 V~/50Hz or 115V~/60Hz
- Fuse: 500mA träge
- Maximum Power Consumption: < 25 W (0,01A at 230 V)
- Dimensions: 2 U, H:88.1 mm, W:483 mm, D:250 mm
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://www.tegeler.com/ClassicEqualizer/Index" target="_blank">https://www.tegeler.com/ClassicEqualizer/Index</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.EQ, HASHTAGS.RACK, HASHTAGS.TWO_U, HASHTAGS.RECORDING, HASHTAGS.MASTERING, HASHTAGS.MIXING, HASHTAGS.LIVE_SOUND],
    manufacturer: BRAND.TEGELER_AUDIO,
  },
  videos: []
}