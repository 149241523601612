import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const tubesummingmixer: IProductData = {
  id: "tegeler-tubesummingmixer",
  name: "Tegeler Audio Manufaktur Tube Summing Mixer (TSM)",
  description: "Tube Summing Mixer",
  images: ["/img/tegeler-tsm-front.jpg", "/img/tegeler-tsm-side.jpg", "/img/tegeler-tsm-back.jpg"],
  details: [{
    label: "Description",
    markdown: `
Many grieve the sound of the era of analog mixer consoles but don’t want to waive the modern conveniences like total recall.

The Tube Summing Mixer gives you the opportunity to bring the sound of analog mixing with the benefits of a digital workflow to the comfort of your own studio.

The analog signal path, tubes and matching transformers, are responsible for processing the signals. The result is a typical analog sound; open & defined yet warm. The mix is not trapped in the box but opens up the whole room.

With 2 × 20 channels, there are enough inputs available to glue together the most demanding mixes. If 40 inputs aren’t enough for you, several Tube Summing Mixers can be cascaded to increase the input count. As you can use more than one Tube Summing Mixer together, nothing stands in the way of your surround mixes.

To ensure the exact reproducibility of a mix, the Tube Summing Mixer is equipped with a stepped output switch instead of a potentiometer. This allows a practical reduction at -3dB increments.

If you wish to insert mono signals into the mix, just insert the signal into the lower TRS jack. The mono signal is then automatically distributed to both channels simultaneously making it appear in the phantom center of the mix.
`}, {
  label: "Features",
  markdown: `
- 2 × 16 balanced inputs via 4 sub D connectors (XLR or TRS via adapter cable, Tascam® layout)
- 2 × 4 balanced TRS inputs (Neutrik®stereo jack)
- Internal mix bus with up to +22 dBu
- Active Tubes and Transformer output stage
- Illuminated VU meters
- Output Level switch in -3db increments
`
  }, {
    label: "Specifications",
    markdown: `
- Frequency Response: 20Hz - 40kHz
- Maximum Input Level: +22 dBu
- Input Impedance: ≥ 10kΩ
- Output Impedance: < 600 Ω
- Maximum Output Level: +24 dBu
- Dynamic Range: ≥ 110 dB
- Voltage: 230 V~/50Hz or 115V~/60Hz
- Fuse: 500mA slow-blow
- Maximum Power Consumption: < 25 W
- Dimensions: 2 U, H:88.1 mm, W:483 mm, D:250 mm
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://www.tegeler.com/TubeSummingMixer/Index" target="_blank">https://www.tegeler.com/TubeSummingMixer/Index</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.SUMMING_MIXER, HASHTAGS.RACK, HASHTAGS.TWO_U, HASHTAGS.MASTERING, HASHTAGS.LIVE_SOUND, HASHTAGS.MIXING],
    manufacturer: BRAND.TEGELER_AUDIO,
  },
  videos: []
}