import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const el9MikeETwinPak: IProductData = {
  id: "empirical-twinpak",
  name: "Empirical Labs ELI TwinPak (Mike-E Pair)",
  description: "Two Mike-E units sold as a pair, essentially creating an Empirical Labs dual channel preamp.",
  images: ["/img/empirical_twinpak.png"],
  details: [{
    label: "Description",
    markdown: `
[See also the single rack version.](/products/empirical-el9-mike-e)  

Two Mike-E units sold as a pair, essentially creating an Empirical Labs dual channel preamp.  
`
  }, {
    label: "Specifications",
    markdown: `
- Frequency Response: 3 Hz to 200 kHz on (No CompSat). CompSat is 3Hz - 150Khz. Optional Transformer output is 6Hz - 80kHz.
- Signal to Noise: 130 dB signal to Noise at 40dB gain. Maximum output is +28dBu.
- Distortion: Rangers between .0006% and 15% depending on mode and settings.
- I/O: Transformer coupled Input, Active and Transformer Outputs available. High quality audio caps used internally.
- Impedance: Input Impedance is above 600 Ohms at all frequencies, transformer coupled. Output impedance is less than 38 ohms. Instrument in Impedance is 332K Ohms.
- Attack/Decay Ranges: .9 - 70mS attack. .15 - 1 Sec.
- Power Consumption: 15 Watts Max.
- Metal Chassis: Single height 1.75" high, 10" deep, 19" wide
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://www.empiricallabs.com/mike-e/" target="_blank">https://www.empiricallabs.com/mike-e/</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.MICPRE, HASHTAGS.RACK, HASHTAGS.TWO_U, HASHTAGS.RECORDING, HASHTAGS.MIXING, HASHTAGS.LIVE_SOUND],
    manufacturer: BRAND.EMPIRICAL_LABS,
  },
  videos: ["https://www.youtube.com/embed/HiUdgEkYrSM", "https://www.youtube.com/embed/1dYGvUl1Pl0", "https://www.youtube.com/embed/mUCSzCWduHg"]
}