
import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const wai2950: IProductData = {
  id: "mother-wai-2950",
  name: "Mother Wai 2950",
  description: "A recreation of the legendary Siemens W295.",
  specialOrder: true,
  images: ["/img/mother_wai_front_center.jpg", "/img/mother_wai_curve.png"],
  details: [{
    label: "Description",
    markdown: `
WAI (wire and iron) 2950, is a 3 band, completely discrete class A inductor based ,500 series eq.
In a way, we recreated the legendary w295, an eq famous for its magically beautiful “high shelf” and real vintage vibe, 
the secret weapon of many producers over the past decades.<br>
<br>
Input and output are balanced by our custom high class transformers. 
A special challenge was the output transformer, which, with the integrated LPF at 20khz
is a really critical element to not lose the well-known sonic character, and the choice
ultimately fell on the 50%Nickl core. Also, the impedance of the input transformer, (which
in the genuine unit was relatively low) was increased to today's standards and all this
resulted in an extended low freq response and a slightly “excited” sound, if we compare it
with the Siemens module. The pcb board is full of NOS bsx46 Phillips transistors, and as
much as possible Siemens NOS film capacitors.<br>
<br>
**How does it sound?**<br>
Completely magical, huge, but still focused low end and incredibly sweet highs, creamy
mid range, very characteristic for an inductor based circuit.<br>
<br>
**What can it be used on?**<br>
Anywhere where you actually want character and sweetness.
`
  }, {
    label: "Features",
    markdown: `
- Circuit: Class A , discrete
- IN: Transformer balanced
- OUT: Transformer balanced
- MID band: 700 ,1k ,1k5 ,2k3 ,3k5 ,5.6k Bell +-8dB Boost-Cut
- HIGH band: 16kHz Bell , +15dB -9dB, 3dB steps Boost-Cut
- LOW  band: 40Hz Shelf, +15dB -9dB, 3dB steps Boost-Cut
- HPF: -3 dB on 70Hz
- Bypass: True Bypass
`
  }, {
    label: "Technical Specs",
    markdown: `
- Maximum input level: +21 dBu
- Maximum output level: +21 dBu
- Input impedance: 10k
- Output Impedance: Less than 75 Ohms
- Noise: <-85 dBA
- THD: 0.09% at +10dBu
- Max. current draw: 100mA`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://www.mother-proaudio.com/wai-2950" target="_blank">https://www.mother-proaudio.com/wai-2950</a>
    `
  }],
  videos: ["https://www.youtube.com/embed/BcLiAjK86so?si=cpgKNreaDnsuR0uS", "https://www.youtube.com/embed/9yeMW9Yqmfc?si=IKHuVxM6pyg3n5Gh"],
  metadata: {
    hashtags: [HASHTAGS.EQ, HASHTAGS.MIXING, HASHTAGS.RECORDING, HASHTAGS.SINGLE_SLOT, HASHTAGS.LIVE_SOUND, HASHTAGS.API_500, HASHTAGS.MASTERING],
    manufacturer: BRAND.MOTHER,
  }
}