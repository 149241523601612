import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const arousor: IProductData = {
  id: "empirical-arousor",
  name: "Empirical Labs Arousor",
  description: "The Arousor™ is the only Distressor plugin made by the makers of the Distressor themselves, Empirical Labs Inc.",
  images: ["/img/empirical_arousor_vst.png"],
  details: [{
    label: "Description",
    markdown: `
The Arousor™ is the only Distressor plugin made by the makers of the Distressor themselves, Empirical Labs Inc.  
It’s been well over 20 years since Empirical Labs released its first compressor, The Distressor.  
From those early days on, Empirical Labs has been bombarded with requests for a great compressor plugin for those working in the box… and it had to be one with the creamy sound and ease-of-use of our own award-winning hardware compressors.  
We are sure you will find the 20+ year wait worth it!

BYPASS ANYTHING
There are some great features available on the Arousor, but sometimes you don’t want to use all of them.  
As of Rev 2.2, you can bypass any parameter to closely monitor the changes you are making or achieve a higher level of transparency.  
Click here to download the latest Rev.

RIVET MODE
Get maximum gain reduction with the Rivet mode along with all of the ratios you know and love from the Distressor.  
Modeled after the Distressor’s Nuke Ratio, Rivet provides the steepest compression ratio a compressor plugin can provide to slam room mics and more.

ATTACK MODIFICATION
Fine-tune the attack curve of the compression envelope with AtMod.  
The attack modification knob slows down the initial slope of the attack envelope to add just a little bit more of the transient back into the track.  
Whether it’s to get a little bit more snap on the snare track or more thump on the electric bass, the AtMod will let the transients through without losing your desired amount of compression.

COLORFUL SATURATION
Add some vintage-style harmonic distortion to any track with the soft clipper.  
Whether you are looking for subtle or extreme amounts of total harmonic distortion, the soft clipper knob merges both of the distortion settings on the Distressor to grant the user complete control of total harmonic distortion without having to adjust the input level of the Arousor.

ADVANCED DETECTOR SIDECHAIN
Dial-in exactly where you want the compression to trigger with 2 bands of sidechain EQ.  
This advanced detector sidechain EQ expands both of the preset detection filter circuits on the Distressor into a controllable hi-pass and parametric sidechain.  
Control the exact point on the frequency spectrum where you want compression to be triggered without losing any low-end.

BLEND EXPERT PANEL
Sometimes, you need just the right amount of parallel processing.  
Click the “e” button in the Blend section of the Arousor to open the Expert Panel where you can precisely match the dry level to the processed signal level.
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://www.empiricallabs.com/product/arousor/" target="_blank">https://www.empiricallabs.com/product/arousor/</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.COMPRESSOR, HASHTAGS.VST, HASHTAGS.RECORDING, HASHTAGS.MIXING, HASHTAGS.MASTERING],
    manufacturer: BRAND.EMPIRICAL_LABS,
  },
  videos: [
    "https://www.youtube.com/embed/iTBbbbPcWb0",
    "https://www.youtube.com/embed/i9tAONpAbqc"
  ]
}