import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const mixingBundle: IProductData = {
  id: "wesaudio-mixing-bundle",
  name: "WesAudio Mixing Bundle",
  description: "Value for money: Get a FREE _TITAN!",
  images: ["/img/wesaudio_mixing_bundle_front.png", "/img/wesaudio_mixing_bundle_back.png"],
  details: [{
    label: "Description",
    markdown: `
Next generation 8 channels of audio processing with ADAT connectivity  

ng500 mixing bundle provides 8 channels of high level analog processing with full digital control via dedicated plug-ins and high quality 8 channels of AD/DA conversion.  

Each ng500 bundle is pre-configured, assembled and fully tested as a single unit in the factory.  

Contains:   
_PROMETHEUS  
_HYPERION  
_RHEA  
_DIONE  
_CALYPSO  

`
  }, {
    label: "Key Features",
    markdown: `
### ng500 Mixing Bundle
- Get _TITAN for FREE with this BUNDLE!
- Perfect analog mixing setup with full digital recall!
- Pultec-style dual mono/stereo/ms equalizer
- Solid state dual mono/stereo/ms parametric equalizer
- True stereo tube vari-mu compressor
- True stereo VCA-based BUS compressor
- 24 Bit up to 96kHz AD/DA conversion through ADAT extension
- Easy integration with DAW HW inserts as each ADAT channel corresponds to a particular slot in _TITAN chassis
- Each unit features analog processing with full digital recall and remote control through dedicated DAW plug-ins (VST3/VST2/AU/AAX)

`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://wesaudio.com/product/ng500-mixing-bundle/" target="_blank">https://wesaudio.com/product/ng500-mixing-bundle/</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.CASES, HASHTAGS.NEXT_GEN, HASHTAGS.STARTING_OUT, HASHTAGS.MIXING, HASHTAGS.RACK, HASHTAGS.THREE_U, HASHTAGS.API_500],
    manufacturer: BRAND.WESAUDIO,
  },
  videos: ["https://www.youtube.com/embed/zU-tarjj9rE"]
}