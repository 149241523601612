
import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const timbre: IProductData = {
  id: "wesaudio-timbre",
  name: "WesAudio Timbre",
  description: "VARI-MU TUBE COMPRESSOR",
  images: ["/img/wesaudio_timbre_front.png", "/img/wesaudio_timbre_back.png"],
  details: [{
    label: "Description",
    markdown: `
Vari-mu Tube Compressor  

As always It’s all about compression so we’ve created “Timbre” – an all tube vari-mu compressor based on the 6386 tube, inspired by the old, famous and much desired Gates STA-Level compressor.  
The Timbre is easy to use, full of color and sweetness. It is a vintage-style compressor made from the highest quality of components.  

Augmenting the design, we have added new features like the third mode of operation with shorter attack time, a high-pass sidechain filter, a link for stereo operation and a bypass function.  
Timbre utilizes a novel programme controlled release circuit which creates density and consistency while minimizing typical compressor side-effects at up to 40dB of gain reduction!  
`
  }, {
    label: "Key Features",
    markdown: `
### Timbre stereo vari-mu tube compressor

- Famous Gates Sta-level vari-mu compression
- All tube balanced circuit with 6386 tube
- High quality, carnhill transformers
- Single/double/triple mode of operation
- 6-step release time switch
- Switchable sidechain high pass filter
- Bypass function
- Link function
`
  }, {
    label: "Specifications",
    markdown: `
- Frequency response	20Hz-30kHz (0.5dB)
- THD+N	up to 1%
- Input impedance	transformer balanced: 600ohm
- Output impedance	transformer balanced: 600ohm
- Max gain	45dB
- Max gain reduction	40dB
- Mode	single, double, triple
- Balanced output and input	(XLR Amphenol)
- Case Rack	2U
- Dimensions	88x483x235 mm
- Weight	7kg
- Warranty	3 years
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://wesaudio.com/product/timbre/" target="_blank">https://wesaudio.com/product/timbre/</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.COMPRESSOR, HASHTAGS.MIXING, HASHTAGS.RECORDING, HASHTAGS.LIVE_SOUND, HASHTAGS.RACK, HASHTAGS.TWO_U],
    manufacturer: BRAND.WESAUDIO,
  }
}