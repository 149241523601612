import { IProductData } from "../../../type/IProductData";
import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";

export const wa12MKII: IProductData = {
  id: "wa-12-500-mkii",
  name: "Warm Audio WA-12 500 MKII.",
  description: "American Console Mic Pre. For the price and quality, this should be one of your first mic pres. For some it's their first and only mic pre. Quality and also at an affordable price, great for those who are just getting started with their analog gear.",
  images: ["/img/wa-12-mkii.jpg"],
  details: [{
    label: "Key Features",
    markdown: `
- Packed with performance  
The WA12-500 MKII is a completely discrete, high voltage, dual-transformer microphone preamplifier design with high gain (+71dB), high headroom, low noise, and an exciting sonic performance – great for all types of recording applications.  
A high quality mic preamp is one of the most critical pieces needed to achieve the elusive ‘larger than life’ sound, and the WA12-500 MKII is more than capable of delivering.
- Premium Components  
The WA12-500 MKII is based around a discrete op-amp, the X731, which is our own reproduction of the vintage classic Melcor 1731, considered by some to be the warmer predecessor to the modern 2520-style op-amp.  
The X731 can achieve vibrant, clean and lifelike tones; but can also be driven into mild saturation at much higher gain levels.  
The effects can be subtle, but very pleasing to the ear.  
The WA12-500 MKII can also accept any discrete op-amp on the market that conforms to the +/-16v, 2520 pinout standard.  
When developing the WA12 MKII our ears played a big part in the design process. Using CineMag USA transformers and an all discrete signal path ensured that the WA12 MKII would have the building blocks to compete with the best sounding preamps on the market. We are very proud to offer such a robust/professional design in a small and affordable package.
`
  }, {
    label: "Specifications",
    markdown: `
- UTILIZES PREMIUM GRADE INPUT AND OUTPUT USA CINEMAG TRANSFORMERS.
- FULLY DISCRETE DESIGN WITH A ROBUST 71DB OF GAIN.
- UTILIZES A DISCRETE 6-PIN SOCKETED 1731 STYLE OPAMP. CAN BE REPLACED WITH OTHER 6-PIN "2520 FOOT-PRINT" OPAMPS FOR VARYING TONE/FLAVOR.
- 20DB PAD.
- EXTERNAL 24V AC POWER PLUG. CURRENT DRAW: 800MA.
- TONE BUTTON – SWITCHES THE INPUT IMPEDANCE FROM 600 OHMS TO 150 OHMS. CHANGES THE TONE OF BOTH THE MIC AND HI-Z INPUTS.
- MIC LEVEL BALANCED INPUT: XLR.
- BALANCED OUTPUTS: XLR AND 1/4″/TRS.
- 48 VOLT PHANTOM POWER.
- LED METER GAIN INDICATION.
- 2 MEG OHM TRUE HI-Z INPUT ON FRONT PANEL. SENDS INSTRUMENT SIGNAL THROUGH ENTIRE CIRCUIT INCLUDING BOTH CINEMAG TRANSFORMERS AND DISCRETE OPAMP. GREAT FOR GUITARS, BASS, KEYBOARDS AND OTHER INSTRUMENTS THAT HAVE A 1/4″ OUTPUT JACK.
- OUTPUT ATTENUATION CONTROL (AFTER THE CINEMAG OUTPUT TRANSFORMER) ALLOWS FOR DRIVING THE PREAMP AND TRANSFORMERS INTO SATURATION.
`
  }, {
    label: "Manufacturer Links",
    markdown: `
  - <a to="https://warmaudio.com/wa12-500mkii/" target="_blank">https://warmaudio.com/wa12-500mkii/</a>
  `
  }],
  metadata: {
    hashtags: [HASHTAGS.MICPRE, HASHTAGS.SINGLE_SLOT, HASHTAGS.STARTING_OUT, HASHTAGS.RECORDING, HASHTAGS.API_500],
    manufacturer: BRAND.WARM_AUDIO,
  }
}