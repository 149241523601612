import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const recordingMixingBundle: IProductData = {
  id: "wesaudio-recording-mixing-bundle",
  name: "WesAudio Recording & Mixing Bundle",
  description: "Value for money: Get a FREE _DIONE!",
  images: ["/img/wesaudio_recording_mixing_bundle_front.png", "/img/wesaudio_recording_mixing_bundle_back.png"],
  details: [{
    label: "Description",
    markdown: `
Next generation 10 channels of audio processing

ng500 mixing bundle features 10 channels of high level analog processing with full digital control via dedicated DAW plug-ins.

Each ng500 bundle is pre-configured, assembled and fully tested as a single unit in the factory.  


Contains:   
2x _PHOEBE  
2x _MIMAS  
_PROMETHEUS   
_RHEA  
_DIONE  
`
  }, {
    label: "Key Features",
    markdown: `
### ng500 Recording & Mixing Bundle
- Get _DIONE for FREE with this BUNDLE!
- Perfect analog recording & mixing setup with full digital recall!
- Pultec-style dual mono/stereo/ms equalizer
- True stereo tube vari-mu compressor
- True stereo VCA-based BUS compressor
- 2 x ‘73 style preamplifiers (each based on 2 carnhill transformers and famous dual amp topology!)
- 2 x _MIMAS 1176-style compressors (each based on 2 carnhill transformers and a lightning fast FET circuit!)
- Each unit features analog processing with full digital recall and remote control through dedicated DAW plug-ins (VST3/VST2/AU/AAX)
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://wesaudio.com/product/ng500-recording-mixing-bundle/" target="_blank">https://wesaudio.com/product/ng500-recording-mixing-bundle/</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.CASES, HASHTAGS.NEXT_GEN, HASHTAGS.STARTING_OUT, HASHTAGS.MIXING, HASHTAGS.RECORDING, HASHTAGS.RACK, HASHTAGS.THREE_U, HASHTAGS.API_500],
    manufacturer: BRAND.WESAUDIO,
  },
  videos: ["https://www.youtube.com/embed/zU-tarjj9rE"]
}