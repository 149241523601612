import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const raumzeitmaschine: IProductData = {
  id: "tegeler-raumzeitmaschine",
  name: "Tegeler Audio Manufaktur Raumzeitmaschine (RZM)",
  description: "Tube Reverb",
  images: ["/img/tegeler-raumzeitmaschine-front.jpg", "/img/tegeler-raumzeitmaschine-side.jpg", "/img/tegeler-raumzeitmaschine-back.jpg"],
  details: [{
    label: "Description",
    markdown: `
The Raumzeitmaschine creates a spatial tone that sounds very natural, transparent and clear on the one hand, but on the other hand it has a nice sound on its very own.
This is achieved through the unique combination of digital and analog components. There is of course a processor that calculates the reverb. This is enriched by the typical analog sound of our devices.

##Unique operating concept
The Raumzeitmaschine offers the most direct access to the right sound with only three parameters.
Instead of plodding through hundreds of parameters (90 percent as non-programmers, not knowing what they're doing), now you can tune the sound to the way your mix needs it.

An operating approach as with a real musical instrument.

##Presets in simple
We have omitted the complicated preset management directly on the device.

If you remember programming the timer on a VCR, then you know why.

The Raumzeitmaschine is equipped with servomotors and thus controllable remotely by the DAW. Conversely, direct changes to the device also land immediately in the Raumzeitmaschine plug-in.
Use the preset management of your DAW or the Raumzeitmaschine plug-in.

It's as simple as that!

## Remote-controlled
All controls of the Raumzeitmaschine are equipped with servomotors.
You can control it directly on the frontpanel, by the internal webserver or by the DAW plug-in (AU, AAX, VST).
Because the Raumzeitmaschine connects to your computer via Ethernet, there is no limit on cable length like using USB. Wireless via your router is also no problem.

## Tubes?
Yes, tubes!

The Raumzeitmaschine has two double triodes per channel as well as input and output transformers. That's why there are only analog inputs and outputs, not digital ones. An important sound component would otherwise be missing without it.

If you like it fatter, you can even try to override the tube from the input stage.

## Not only 'Natural' sounds
Although the goal was a natural surround sound, it is also possible to create unnatural sounds too.

A storeroom with 3 seconds of decay time?
Easy.
Sounds unusual.
But can fix the mix.
Can be tried out within one second.

## How does it sound in comparison?
Different to all the Lexi-TC-Event-Bri-Qs.

The Raumzeitmaschine will definitely not replace a plate or reverb spring.
But it's unique spatiality is guaranteed to find its place as the main reverb in your setup.

Listen for yourself and be enchanted.
`}, {
  label: "Features",
  markdown: `
- True stereo
- Input: XLR, transformer-balanced
- Output: XLR, transformer-balanced
- Reverb processor
- Adjustable Pre-delay, Room size, Density and Decay Time
- Ethernet
- Controllable by the webpage of the device or by plug-in (VST, AU, AAX)
- Servo pots
- Dynamic: >110dB unweighted
- Attenuation-to-crosstalk ratio L/R >90 dB @ 1 kHz
- Internal power supply (25W)
- Height: 88,1mm (2u), Width: 483mm, Depth: 250mm
`
  }, {
    label: "Specifications",
    markdown: `
- Frequency Response: 10Hz - 22kHz
- Maximum Input Level: +22 dBu
- Input Impedance: ≥ 10kΩ
- Output Impedance: < 600 Ω
- Maximum Output Level: +24 dBu
- Dynamic Range: ≥ 110 dB
- Voltage: 110- 240 V~/50-60 Hz
- Fuse: 500mA slow-blow
- Maximum Power Consumption: < 25 W
- Dimensions: 2 U, H:88.1 mm, W:483 mm, D:250 mm
    `
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://www.tegeler.com/Raumzeitmaschine/Index" target="_blank">https://www.tegeler.com/Raumzeitmaschine/Index</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.FX, HASHTAGS.RACK, HASHTAGS.TWO_U, HASHTAGS.RECORDING, HASHTAGS.MIXING, HASHTAGS.LIVE_SOUND],
    manufacturer: BRAND.TEGELER_AUDIO,
  },
  videos: []
}