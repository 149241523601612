
import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const action: IProductData = {
  id: "purple-audio-action",
  name: "Purple Audio 5C1 Action",
  description: "A FET Compressor similar to the MC77",
  specialOrder: true,
  images: ["/img/purpleaudio_action_front.png", "/img/purpleaudio_action_left.png"],
  details: [{
    label: "Description",
    markdown: `
Designed to work with standard 500 series racks.  
The Action by Purple Audio features a constant impedance 600 ohm input attenuator feeding the input transformer, just like our MC77 units.  
This makes the input more resistant to overload by hot signals.  
After the input transformer, the signal is attenuated by the same gain reduction FET as in the MC77.  
The attenuated signal is buffered by our class A KDJ3 opamp, which feeds the output fader.  
Lastly, our class AB KDJ4 output opamp drives our custom hexafilar nickel and steel output transformer.  
The total gain (with no attenuation) is 45dB, just like the MC77.  

Attack and release times cover a broader range than the MC77, which can cause distortion… which may or may not be what you are looking for.  
Use at extremes with caution. The ratios are the same as the MC77, and the threshold moves up as the ratio goes up, just like the MC77.  

Stereo linking is accomplished by cross connecting pins 3 & 6 on the edge connector.  
On the Purple Sweet Ten Rack, there is a second output 1/4″ that can be used to link two Actions.  
The link is accomplished using a TRS to TRS cable with tip on one TRS connecting to RING on the other TRS and vice versa.  
Plug in the cable, flip the front panel switches and you are linked.  
Non-Purple racks do not have an easy way to connect pins 3 and 6, and will need modification to link two Action units.  
`
    }, {
      label: "Features",
      markdown: `
• Input attenuator feeding input transformer  
• Output level control  
• Input and output tricolor LED metering  
• Eight LED GR metering  
• Five ratios including “All buttons in” mode  
• Attack and Release controls  
• True Relay Bypass  
• Stereo Linking  
• Compression on/off switch  
• Discrete signal path  
• Over Current Resettable Fused
(prevents module issues from affecting your Purple Audio Sweet Ten Rack)  
• ROHS – Lead Free  
`
  }, {
    label: "Specifications",
    markdown: `
• Total Gain: 45dBu
• Ratios: 4:1, 8:1, 12:1, 20:1, ∞:1 (All buttons in)
• Attack time: Variable from 800 to 20 microseconds
• Release time: Variable from 1.1 seconds to 50 mls
• Input impedance: 600 ohms
• Output impedance: 600 ohms
• Current consumption: 90ma
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="http://www.purpleaudio.com/products/action/" target="_blank">http://www.purpleaudio.com/products/action/</a>
- <a to="http://www.purpleaudio.com/product/PAAction.PDF" target="_blank">Recall sheet</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.COMPRESSOR, HASHTAGS.MIXING, HASHTAGS.SINGLE_SLOT, HASHTAGS.LIVE_SOUND, HASHTAGS.API_500],
    manufacturer: BRAND.PURPLE_AUDIO,
  },
  videos: ["https://www.youtube.com/embed/xBrA8uWJ8xc", "https://www.youtube.com/embed/QIzixuSvsto"]
}