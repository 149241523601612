import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const hyperion: IProductData = {
  id: "wesaudio-hyperion",
  name: "WesAudio _Hyperion",
  description: "NG500 fully analog parametric equalizer with digital recall",
  images: ["/img/wesaudio_hyperion_front.png", "/img/wesaudio_hyperion_back.png"],
  details: [{
    label: "Description",
    markdown: `
NG500 fully analog parametric equalizer with digital recall  

_HYPERION is fully analog parametric EQ with +24dBu of headroom.  

It features a lot of innovative improvements that no other processor on the market provides.  

Its innovative design utilizes 15 VCAs per channel to provide essentially noiseless parameter changes and very musical nature.  

_HYPERION is next product in NG500 line where total recall is implemented through front panel USB socket or _TITAN chassis.  
`
  }, {
    label: "Key Features",
    markdown: `
### _HYPERION
- +24dBu of headroom
- Stereo / Dual mono / Mid/Side operation mode
- 4-Band EQ with switchable gain range for 5db or 15dB boost or cut per band:
- HPF with 12dB/Oct or 6 dB/Oct slopes
- Each band features its own Bypass switch
- Essentially Noiseless parameter changes
- Proprietary THD implementation
- Flexible DAW/Live plug-in control for instant recall
- 12 touch sensitive encoders allows to record automation
- True bypass
- In and OUT metering with clip detection
- A/B compare feature
`
  }, {
    label: "Specifications",
    markdown: `
- Frequency response	10Hz-150kHz (-2dB)
- THD+N all bands gain “0”	0.003% (1kHz,0dBu)
- THD+N +15dB band boost	<0.15% (1kHz,0dBu)
- Input impedance	10kohm
- Output impedance	< 100ohm
- Max signal level	+24dBu
- Crosstalk	< -100dB
- THD (MID switch)	1%
- THD (HIGH switch)	2.5%
- Power consumption	150mA per rail
- Dimensions	76x133x158mm
- Warranty	2 years
`
  }, {
    label: "Notes",
    markdown: `
Unit needs to warm up for about 5 minutes before usage as its temperature may affect some internal variables – like frequencies of each band, and very slightly gain and Q settings.  
Each band can boost or cut in configurable gain mode (It affects also OUTPUT control):  
- 15dB boost or cut in 0.25 dB steps,
- 5dB boost or cut in 0.083 dB steps,
Each frequency control has predefined amount of steps in logarithmic scale:  
- HF frequency – 256 steps,
- HMF frequency – 256 steps,
- LMF frequency – 256 steps,
- LF frequency – 200 steps,
- HPF frequency – 128 steps.
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://wesaudio.com/product/_hyperion/" target="_blank">https://wesaudio.com/product/_hyperion/</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.EQ, HASHTAGS.DUAL_SLOT, HASHTAGS.NEXT_GEN, HASHTAGS.MIXING, HASHTAGS.MASTERING, HASHTAGS.LIVE_SOUND, HASHTAGS.API_500],
    manufacturer: BRAND.WESAUDIO,
  },
  videos: [
    "https://www.youtube.com/embed/gDgN66CNDnc",
    "https://www.youtube.com/embed/h2mNYpASiJg"
  ]
}