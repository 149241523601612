import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const magnetismus2: IProductData = {
  id: "tegeler-magnetismus-2",
  name: "Tegeler Audio Manufaktur Magnetismus 2",
  description: "Transient Shaper & VCA Compressor",
  images: ["/img/tegeler-magnetismus-front.jpg", "/img/tegeler-magnetismus-back.jpg"],
  details: [{
    label: "Description",
    markdown: `
The Magnetimus 2 is a good candidate to be Tegeler’s most versatile device: it was first conceived, among other things, for processing transient-rich signals, such as drums, synthesizers, and acoustic guitars. It combines a modern, flexible VCA compressor with a downstream transient shaper. The result is a thick, rich sound, that helps integrate tracks better into the mix, similar to a tape recording.

## Transient shaper
The transient shaper does not simply change the level of the transients, but, much like a tape machine, it rounds out the transients. The three different Flux Modes (Eisen, Kobalt, and Nickel) will run your signal through different transformers that emulate analogically the saturation of tapes made respectively of iron, cobalt, and nickel.

## VCA compressor
In its first stage, you will find a powerful VCA compressor, with Threshold, Timing, Ratio, and Make-Up Gain controls. As its brother Creme does, the Magnetismus 2 can compress any stereo signal to ensure that the peaks are attenuated without compromising the dynamic range of your mix. More than anything, it can - and should - be used to “glue” mixes together.

## Modi
The way the Flux Mode does that is by changing the time window for the processed transients slightly, causing the transient to be rounded more or less depending on the width of the transients. With the Intensity knob, you can control how much the transient shaping will change the original signal, enabling you to sculpt your envelope as finely as possible.

## Transformer stage
The additional transformer at the output adds another bit of density, which will bring your modified up as much as you want, allowing that final touch of punch that a mix sometimes needs.

Using more extreme settings, the Magnetismus 2 can also be very helpful in creative sound design, by crushing or destructing audio signals; it can be used as a very straightforward drum smasher, for example.
`}, {
  label: "Features",
  markdown: `
- Stereo Unit
- Input: Balanced XLR
- Output: Transformer Balanced XLR
- VCA Compressor
- Adjustable threshold and ratio
- Wide range of adjustable timing
- LED gain reduction meter
- Adjustable level and intensity of transient processing
- Illuminated VU meter for working point control
- Adjustable output level
- Stepped Potentiometers
- Bypass Switch
- Internal power supply
- Height: 88,1mm (2HE), width: 483mm, depth: 250mm
`
  }, {
    label: "Specifications",
    markdown: `
- Frequency Response: 20Hz - 22kHz
- Maximum Input Level: +20 dBu
- Input Impedance: ≥ 2.4kΩ
- Output Impedance: < 600 Ω
- Maximum Output Level: +24 dBu
- Dynamic Range: ≥ 95 dB
- Voltage: 110- 240 V~/50-60 Hz
- Fuse: 500mA slow-blow
- Maximum Power Consumption: < 25 W
- Dimensions: 2 U, H:88.1 mm, W:483 mm, D:250 mm
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://www.tegeler.com/Magnetismus2/Index" target="_blank">https://www.tegeler.com/Magnetismus2/Index</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.COMPRESSOR, HASHTAGS.RACK, HASHTAGS.TWO_U, HASHTAGS.RECORDING, HASHTAGS.MASTERING, HASHTAGS.MIXING, HASHTAGS.LIVE_SOUND],
    manufacturer: BRAND.TEGELER_AUDIO,
  },
  videos: []
}