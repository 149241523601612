import { HASHTAGS } from "../../type/EnumHashtags";
import { IResource } from "../../type/IResource";

export const monitorController: IResource = {
  id: "monitor-controllers",
  title: "Do I Need A Monitor Controller?",
  date: "21 Sep 2022",
  preview: "Isn't the speaker outs and headphone outs of my audio interface sufficient? Do I need a monitor controller? Aren't my audio interface's headphone amplifiers good enough?",
  mdBody: `## **Studio Monitor Controllers?**
Why should you get a dedicated controller for you monitors and headphones? This question is easy to answer if you have more than one pair of monitors. 
They give you the ability to audition your track on different combinations of speakers. If you're in a small studio or even in your bedroom the question remains...

### **Should I Get A Monitor Controller?**
Yes. My advice would be yes get one when your budget allows you to. I had been using the SSL 2 audio interface ever since it came out. 
The brand is well known and you would expect quality from it so I never had an issue with the quality it was delivering to my speakers and headphones.   

Until the day I learned to check my mixes in mono. I learned how to do it using plugins but when I wanted to get really quick in doing it I felt the need for a physical button. 
The SSL 2 didn't have that. That was when I started looking into entry monitor controllers. Not too fancy, and not too many bells and whistles, just a mono switch and to buttons to mute my speakers. 

### **Reason 1: Checking Your Mixes in Mono**
You can do it in your DAW through plugins however if you're keen on doing things efficiently and without disruption, get a monitor controller with a mono button. 
It's one of those time savers you want when you mix and master. 

### **Reason 2: Headphone Stereo Representation**
This was an unintentional find. I have heard so much about the mixing with speakers vs headphones debate and the downside of headphones is that it's not the true way how we hear things in real life.  

Enter crossfeed. I was skeptical at first and avoided mixing in headphones. I did feel that it was unnatural. However when I decided to get a monitor controller, I decided to try one with a crossfeed control.

I got the [2Control](https://sinamex.com/product/spl-2control-sinamex-singapore/) by SPL and boy was I surprised by the results. I felt indeed what I was hearing was more natural and didn't feel a disconnect compared to without the crossfeed. 

### **Reason 3: Better Amplifiers**
This was another reason that I didn't realise until I tried it out. When I tried the SPL 2Control I did also notice I could hear details that I didn't quite hear without it. 
I was still using my SSL 2, the only difference were the amplifiers in the 2Control. There was some magical transformations enabling me to hear details I couldn't before. 

When I experienced these differences, I can't help but recommend people who are just starting out to plan to get a monitor control or headphone amplifier.   

If you have an audio interface that have integrated monitor controls like the mono button, or muting specific speaker outputs that's great! I would still encourage you to then consider headphone amplifiers if you mix with headphones and get one with a crossfeed function.  
So far, SPL is the market leader in that regard. 
`,
  metadata: {
    hashtags: [HASHTAGS.MIXING, HASHTAGS.MASTERING, HASHTAGS.RECORDING, HASHTAGS.STARTING_OUT]
  }
}