import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const pump: IProductData = {
  id: "empirical-pump",
  name: "Empirical Labs ELI Pump",
  description: "500 series VCA compressor with 8 ratios, switchable saturation and detector high pass, and variable AtMod (Attack Modification) control. Vertical faceplate.",
  images: ["/img/empirical_pump_front.png", "/img/empirical_pump_side.png", "/img/empirical_pump_angle.png"],
  details: [{
    label: "Description",
    markdown: `
Originally imagined in 2014, PUMP is a single channel, 500 series rack mount compressor loaded with the kinds of features and performance you’ve come to expect from Empirical Labs. 
This module has serious heritage and shares family DNA with notable ELI processors including the renowned Distressor, Arousor, and Mike-E. 
The PUMP is destined to be a “go-to” processor for the creative audio pro that has a toolbox built around no compromise performance, quality, and value.

Starting at the top, PUMP includes controls for Input Level with a clip (BAD) indicator. 
There are 8 selectable compression ratios from 2:1 to 20:1 and everything in between, each with their own unique curve. 
Independent digitally-stepped knobs provide precise recall of Attack and Release selections – including a soft Opto-Coupler style Release. 
PUMP has a switchable 110Hz High Pass Filter, comprehensive Gain Reduction metering, Attack Modification (AtMod) to slow the initial onset of the attack envelope to preserve more transient content for greater overall impact – with a Bypass selection to go dry.
`
}, {
    label: "Attack Modification",
      markdown: `
One of the most unique features of PUMP is AtMod, short for Attack Modification. First seen is our Arousor plug-in, AtMod is a feature never before found in a hardware compressor.

AtMod gives users the ability to slow down the initial slope of the attack envelope to add just a little bit more of the transient back into the track. 
Whether it’s to get a little bit more snap on the snare track or more thump on the electric bass, the AtMod will let the transients through without losing your desired amount of compression.

`  }, {
    label: "Specifications",
    markdown: `
- Frequency Response – 5 Hz to 175 kHz (-3dB points).
- Dynamic Range – 125 dB from maximum output (.5%THD soft clipping) to minimum output. Noise floor with Output on 0 is typically – 101dBu.
- Distortion – between .0035% and 15% at 1KHz, depending on mode and settings.
- I/O – Maximum Input before hard clipping – +22dBm, “ Line In” mode. Maximum Output (Mix at 0) – +28.5dBm
- Impedance – 120k Ohm, Instrument In Mode, output impedance is less than 75 ohms.  20K input impedance in balanced Line in mode.
- Compressor Time Constants – Attack: 200µs to 40ms. Release: 50ms to 500ms, plus Opto setting. Stereo Linkable for stereo tracks.
- Detector HP Filter – Switchable, fixed at 110Hz
- Attack Modification (AtMod) – Slows the initial onset of the attack envelope allowing more transient back into the track.
- Saturation Soft Clipper – based on the same circuit found in the Mike-E CompSat section.
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://www.empiricallabs.com/pump/" target="_blank">https://www.empiricallabs.com/pump/</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.COMPRESSOR, HASHTAGS.SINGLE_SLOT, HASHTAGS.RECORDING, HASHTAGS.MIXING, HASHTAGS.MASTERING, HASHTAGS.LIVE_SOUND],
    manufacturer: BRAND.EMPIRICAL_LABS,
  },
  videos: ["https://www.youtube.com/embed/6Zd1V-CM-84?si=gR1ntGESs8IHzrzL", "https://www.youtube.com/embed/b_rkoU9fBA0?si=OV36o7knzqeP0Q1C"]
}