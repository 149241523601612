import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const prometheus: IProductData = {
  id: "wesaudio-prometheus",
  name: "WesAudio _Prometheus",
  description: "NG500 fully analog passive equalizer with digital recall",
  images: ["/img/wesaudio_prometheus_front.png", "/img/wesaudio_prometheus_back.png"],
  details: [{
    label: "Description",
    markdown: `
NG500 fully analog passive equalizer with digital recall  

_PROMETHEUS is fully analog passive EQ with +24dBu of headroom.  

It features pultec-style warm sound with a lot of innovative improvements.  

Its modern approach to design allows instant recall of your classic vibe through very flexible plugin available in most common formats.  

_PROMETHEUS as all ng500 products, integrates with your DAW environment via front panel USB socket or _TITAN chassis.  
`
  }, {
    label: "Key Features",
    markdown: `
### _PROMETHEUS equalizer with digital recall
- +24dBu of headroom
- Stereo / Dual mono / Mid/Side operation mode
- Passive equalizer (Pultec-style) design based on inductors
- Analog inductor
- Proprietary THD implementation
- Flexible DAW/Live plug-in control for instant recall
- 6 touch sensitive encoders allows to record automation
- True bypass
- In and OUT metering with clip detection
- A/B compare feature
`
  }, {
    label: "Specifications",
    markdown: `
- Frequency response	10Hz-150kHz (-2dB)
- THD+N < 0.01%	(1kHz,0dBu)
- Input impedance	10kohm
- Output impedance	< 100ohm
- Max signal level	+24dBu
- Crosstalk	< -100dB
- THD (MID switch)	1%
- THD (HIGH switch)	2.5%
- Power consumption	70mA per rail
- Dimensions	76x133x158mm
- Warranty 2 years
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://wesaudio.com/product/_prometheus/" target="_blank">https://wesaudio.com/product/_prometheus/</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.EQ, HASHTAGS.DUAL_SLOT, HASHTAGS.NEXT_GEN, HASHTAGS.MIXING, HASHTAGS.MASTERING, HASHTAGS.LIVE_SOUND, HASHTAGS.API_500],
    manufacturer: BRAND.WESAUDIO,
  },
  videos: ["https://www.youtube.com/embed/z4rN4z_7qMA"]
}