import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const varituberecordingchannel: IProductData = {
  id: "tegeler-varituberecordingchannel",
  name: "Tegeler Audio Manufaktur Vari Tube Recording Channel (VTRC)",
  description: "Vari Tube Recording Channel Strip",
  images: ["/img/tegeler-vtrc-front.jpg", "/img/tegeler-vtrc-side.jpg", "/img/tegeler-vtrc-back.jpg"],
  details: [{
    label: "Description",
    markdown: `
You have experience with digital music production and with handling plug-ins but are aspiring to reach the professional league?
The digital sound editing of voices and solo instruments makes sense if you want to work mobile in the pre-production stage or want to quickly test certain equalizer or compressor types using their digital clones.
During the completion of your mix with the plug-ins, you realise that recorded voices don’t become prevalent, don’t integrate into your mix and clearly sound adapted.

Maybe you made the right decision in quality and bought a good microphone, however a premium mixing console is, to continue with quality, outside of your budget.
So far, you have vainly looked for a solution to that problem. A combination of a premium pre-amplifier, compressor and equalizer whic his made up of the finest components is available in only one piece of equipment and for an exceptionally fair price that is reachable for studios projects.

We at Tegeler Audio Manufactur as musicians had the very same problem and therefore shifted our focus towards the development of such a device. The VTRC. The Vari Tube Recording Channel gives your recording the most up market boutique sound and confident powers of self-assertion, while at the same time creating a perfect integration into the mix.
The device will astonish you with its sound results that are accredited to other high end devices within the top price range when used during blind tests.
It is the first sound refiner worldwide which allows for the combined operation of vari-q and optical compression and therewith offers a sound-spectrum ranging from clean precision to a silky warm vintage sound.

## Technology
Due to the variable nature of the preamp, you can decide on color of the sound of your recording varying from clean direct to vintage fat.
With the passive equalizer built after the Pultec principle, you can add silky soft highs to your recording, give your voice more volume or filter out disruptive frequencies within the area of keynotes.
The combined operation of vari-µ and optical compression of the VTC is a unique worldwide feature that extends your sound spectrum with a fantastically sounding compressor tone.

## Optical Compressor
An LED beams onto a light-sensitive resistor and regulates the gain depending on the level. The automatic control of the compressor timing is additionally supported through the memory effect of the resistor. The result is an open-airy and softer compression which keeps the level nicely static but hardly alters the microdynamics of the signal.

## Vari-Tube Compressor
With this setting the amplification of a control tube is influenced directly. The control happens directly through the optical compressor. The result is a sound that will find its place even in a dense mix with a sinister presence while never losing its warmth.
`}, {
  label: "General Features",
  markdown: `
- Tube device (3 Triodes)
- Class-A circuit
- Frequency response: 20 – 20.000 Hz +/- 1,5dB
- Stepped Potentiometers
- Transformer balanced inputs and output
- Internal power supply with toroidal transformer
- Height: 132mm (3U), width: 483mm, depth: 250mm  
`
  }, {
    label: "Microphone Preamp Features",
    markdown: `
- Microphone preamp with transformer / 48V / Phase
- True 48V phantom power
- Phase switch
- High-impedance instrument input(100 kOhm)
- Character changeable from clean to fat
    `
  }, {
    label: "Equalizer Features",
    markdown: `
- Low-cut: 80-160Hz
- Low-mid boost: 80-120-200-300-500-700-1000 Hz
- Mid-cut: 200-300-500-700 Hz 1-1,5-2-3-4-5-7 kHz
- High boost: 1,5-2-3-4-5-7-10-12-16-20-24 kHz
  `
  }, {
    label: "Compressor",
    markdown: `
- Opto compressor
- Vari-Tube compressor (vari-µ)
- Soft-Knee
- Program-adaptive attack and release timing
    `
  }, {
    label: "Specifications",
    markdown: `
- Frequency Response: 20Hz - 22kHz
- Maximum Mic Input Level: +12 dBu
- Maximum Line Input Level: +20 dBu
- Maximum Instrument Input Level: +6 dBu
- Line Input Impedance: ≥ 2.4kΩ
- Instrument Input Impedance: ≥ 100kΩ
- Output Impedance: < 600 Ω
- Maximum Output Level: +24 dBu
- Dynamic Range: ≥ 95 dB
- Voltage: 230 V~/50Hz or 115V~/60Hz
- Fuse: 500 mA slow-blow
- Maximum Power Consumption: < 25 W
- Dimensions: 3 U, H:132 mm, W:483 mm, D:250 mm
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://www.tegeler.com/VariTubeRecordingChannel/Index" target="_blank">https://www.tegeler.com/VariTubeRecordingChannel/Index</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.MICPRE, HASHTAGS.COMPRESSOR, HASHTAGS.RACK, HASHTAGS.THREE_U, HASHTAGS.RECORDING, HASHTAGS.LIVE_SOUND, HASHTAGS.MIXING],
    manufacturer: BRAND.TEGELER_AUDIO,
  },
  videos: []
}