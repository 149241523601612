import { HASHTAGS } from "../../type/EnumHashtags";
import { IResource } from "../../type/IResource";

export const nextGen500: IResource = {
  id: "next-generation-500-series-gear",
  title: "What Are Next Generation 500 Series Gear?",
  img: "/img/resource-next-generation-500-series-gear.jpg",
  imgLabel: "ngBusComp and titan",
  date: "4 February 2022",
  preview: "We take a look at how next generation 500 series gear can improve your existing workflow.",
  mdBody: `## **Next Generation 500 series Lunchboxes**
We are entering a new generation of lunchboxes - an era of hybrid workflows. Taking advantage of both analog hardware and digital software.  
There are two ways manufacturers are bringing about this revolution: 
- Digitalising the Chassis (lunchboxes) and
- Digitalising the 500 modules  

### **Digital chassis: Audio Interface Integration**
One way manufacturers are doing this is by integrating an interface with the physical chassis. 
An example is the [Craneborne 500R8](/products/p/craneborne-500r8). Craneborne included a USB audio interface and a summing mixer into their chassis. 
This allows your computer to recognise the chassis as an audio interface. Check out the difference between their 500R8 and the 500ADAT [here](https://www.soundtech.co.uk/musicians-blog/what-are-the-differences-between-cranborne-audio-500r8-500adat?__hstc=30847456.663753ca337ba7aa6e507cb054f31e79.1652665786724.1652665786724.1652665786724.1&__hssc=30847456.1.1652665786724&__hsfp=3745141232&hsCtaTracking=bb10a4e3-1045-410d-8a5e-eaaffc9d6422%7Cd306a4cd-34df-48a8-9585-295915f7b9f4).  

The advantage here is convenience. You can eliminate a few hardware required to attain a hybrid workflow. For one, you would not need an additional audio interface. 
Although if you require really high end AD/DA converters, then you may have your preferred brand and this may not be an advantage in that circumstance.
However for the rest of us, Craneborne has used quality converters which is more than sufficient for the rest of us. Which means one less hardware and less cables!

If you have worked on any digital mixer like a SSL Big Six, you would have experienced the convenience of routing audio from your PC back out to the mixer through a usb cable and in a small form factor. 
This will be the same advantage the 500R8 will give you. Less cables, less space taken up and the advantages of digital connectivity. 

### **Digital chassis: Digital Control**
Another way manufacturers are digitalising the chassis is by allowing for digital control. 
Not to be confused with an audio interface, as there is no conversion of audio from analog to digital. 
The digital control allows for overcoming one of analog's pain points - recall.  

When close and open our projects in our DAW, whatever plugin settings are saved. With analog, we have to do the saving on a piece of paper - writing down the settings we used. 
This is what we call analog recall. With digital control, this allows software to help save the settings and restore the settings. WesAudio is an example of such an implementation. 
Check out their [titan chassis](/products/p/wesaudio-titan). It also allows for other added abilities like automation - can you imagine automation of analog modules!  

### **Digital 500 modules: Digital Control**
When it comes to gear, we have all sorts of existing gear. 
As such to allow us to continue utilising our existing equipment like an existing chassis, manufacturers have also looked into digitalising 500 modules.  
This brings the advantages of digital control to analog equipment and also lower the barrier to entry. You do not need a digital chassis! 
You can plug in your digital 500 series module into a fully analog chassis, and still get the digital control via a USB cable direct to your 500 series module. 

WesAudio and Bettermaker are examples of manufacturers that took this approach. 

### **Digital 500 modules: Audio Interface**
How about an audio interface as a 500 series module? That isn't an issue according to WesAudio, who came up with their [Calypso module](/products/p/wesaudio-calypso).  
It works on both their own digital titan chassis, or a standard chassis. This gives you the ability to digitalize any standard 500 series chassis!  


### **Overall**
The majority of us want to produce quality music that can be heard by our audiences through online music platforms or in a live environment. 
Next generation 500 series gear are making it more convenient and accessible to do so. 
To me, the best advantage of next generation 500 series gear is the reduction in the number hardware and cables required. 
You can technically digitalise your analog gear using audio interfaces, however the amount of cables and setup required is a lot. 
It locks your setup as well in a specific location as to tear down and setup again is a pain. 
The portability of the entire setup allows your gear to be used in multiple situations and that is a great gain!  

If you're just starting out or already have existing modules, I would encourage you to take the next step into the next generation!
  `,
  metadata: {
    hashtags: [HASHTAGS.NEXT_GEN, HASHTAGS.MASTERING, HASHTAGS.LIVE_SOUND, HASHTAGS.RECORDING]
  }
}